import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as api from '../../services/api_service'
import Skeleton from 'react-loading-skeleton'
import NewTicket from '../../components/NewTicket'
import AppHelpers from '../../hooks/AppHook';
import classNames from 'classnames';

function Tickets() {

    const navigate = useNavigate()
    const { truncate, theme } = AppHelpers()
    const [tickets, setTickets] = useState([])
    const [isFetchingTickets, setisFetchingTickets] = useState(true)
    const [filteredTickets, setFilteredTickets] = useState([])
    const [paginatedTickets, setPaginatedTickets] = useState([])
    // const [sort, setSort] = useState('all')
    const [activePage, setActivePage] = useState(0)
    const paginate = 10

    const tickets_ = useMemo(() => {
        return tickets.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }, [tickets])

    useEffect(() => {
        async function getTickets() {
            try {
                const resp = await api.tickets();
                console.log('all', resp.data.data)
                setisFetchingTickets(false)
                setTickets(resp.data.data)
            } catch (error) {
                setisFetchingTickets(false)
                console.log(error)
            }
        }

        getTickets()
    }, [])

    useEffect(() => {
        setFilteredTickets(tickets_)
        setPaginatedTickets(tickets_.slice(0, paginate))
    }, [tickets_, paginate])

    useEffect(() => {
        setPaginatedTickets(filteredTickets.slice(0, paginate))
    }, [filteredTickets, paginate])

    const allPages = useMemo(() => {
        return Math.ceil(filteredTickets.length / paginate)
    }, [filteredTickets, paginate])

    // function handleSort(data) {
    //     if (data === 'month') {
    //         let now = new Date()

    //         let filter_ = tickets.filter((e) => {
    //             let newDate = new Date(e.created_at)
    //             return newDate.getMonth() === now.getMonth() && newDate.getFullYear() === now.getFullYear()
    //         })

    //         setFilteredTickets(filter_)
    //     } else {
    //         setFilteredTickets(tickets)
    //     }
    //     setSort(data)
    //     setActivePage(0)
    // }

    function handlePaginate(data) {
        let newPageStart = data * paginate
        setPaginatedTickets(filteredTickets.slice(newPageStart, newPageStart + paginate))
        setActivePage(data)
    }

    return (

        <div className="col-12 col-lg-8 mt-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
                <h1 className={classNames("h5 mb-3", {
                        "text-dark-white": theme === "dark"
                    })}>Support Tickets</h1>
                <NewTicket setTickets={setTickets} />
            </div>

            <div className={classNames("card border-gray-300", {
                        "bg-dark-card text-dark-white": theme === "dark"
                    })}>
                <div className="card-body d-flex flex-row align-items-center flex-0 border-bottom">

                    {
                        paginatedTickets.length > 0 ?
                            <div style={{ width: '100%', height: 'auto' }}>
                                <div className="table-responsive">
                                    <table className='table table-white border-gray-300'>
                                        <thead>
                                            <tr className={classNames({
                                            "text-dark-white": theme === "dark"
                                        })}>
                                                <th>Title</th>
                                                <th>Status</th>
                                                <th><i className='fa fa-clock' /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                paginatedTickets.map((e, k) => (
                                                    <tr key={k} style={{ cursor: 'pointer' }} onClick={() => navigate(`/user/ticket/${e.id}`)} className={classNames({
                                                        "text-dark-white": theme === "dark"
                                                    })}>
                                                        <td><span className={classNames("h6 text-sm", {
                                                            "text-dark-white": theme === "dark"
                                                        })} style={{ textDecoration: 'underline' }}>{truncate(e.title, 40)}</span></td>
                                                        <td>
                                                            <div className={e.status === 1 ? 'text-warning' : 'text-primary'}><span className="h6 text-sm">{e.status === 1 ? 'Opened' : 'Closed'}</span></div>
                                                            <div style={{ fontSize: '12px' }}><b>#{e.ticket_id}</b></div>
                                                        </td>
                                                        <td>{e.created_at}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                {allPages > 0 && filteredTickets.length > paginate &&
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-center mt-5">
                                            <li className="page-item"><button className="page-link" onClick={() => activePage > 0 && handlePaginate(activePage - 1)}>Previous</button></li>
                                            {
                                                Array.from(Array(allPages)).map((e, k) => (
                                                    <li key={k} className={activePage === k ? 'page-item active' : 'page-item'} ><button className="page-link" onClick={() => handlePaginate(k)}>{k + 1}</button></li>
                                                ))
                                            }
                                            <li className="page-item"><button className="page-link" onClick={() => activePage + 1 < allPages && handlePaginate(activePage + 1)}>Next</button></li>

                                        </ul>
                                    </nav>
                                }
                            </div> : isFetchingTickets ?
                                <div style={{ width: '100%', height: 'auto' }}>
                                    <div className='table-responsive'>
                                        <table className='table table-white border-gray-300'>
                                            <thead>
                                                <tr className={classNames({
                                            "text-dark-white": theme === "dark"
                                        })}>
                                                    <th>Title</th>
                                                    <th>Status</th>
                                                    <th><i className='fa fa-clock' /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.from(Array(6)).map((e, k) => (
                                                    <tr key={k}>
                                                        <td><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={150} /></td>
                                                        <td><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={100} /></td><td><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={100} /></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div> :
                                <div>
                                    <p>No result found</p>
                                </div>
                    }

                </div>
            </div>

        </div>

    )
}

export default Tickets