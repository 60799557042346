import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import UseAuth from '../../hooks/UseAuth'
import * as api from '../../services/api_service'
import AppHelpers from '../../hooks/AppHook';
import classNames from 'classnames';

function Security() {

  const {theme} = AppHelpers();
  const { isAuthenticated, logoutDevice, isLoggingOut } = UseAuth()
  const [activities, setActivities] = useState([])
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const oldPasswordRef = useRef('')
  const newPasswordRef = useRef('')
  const confirmPasswordRef = useRef('')
  const [isSaving, setIsSaving] = useState(false)
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    async function getActivities() {
      try {
        const resp = await api.activities()
        setActivities(resp.data.data)
        setIsFetching(false)
      } catch (error) {
        console.log(error)
        setIsFetching(false)
      }
    }

    getActivities()
  }, [])

  const handleValidate = useMemo(() => {
    return oldPassword === '' || newPassword === '' || confirmPassword === '' || isSaving || !isAuthenticated || passwordError ? true : false
  }, [oldPassword, newPassword, confirmPassword, isSaving, isAuthenticated, passwordError])

  function validatePassword() {
    if ((newPassword.length < 6 && confirmPassword.length > 0) || (confirmPassword.length < 6 && newPassword.length > 0)) {
      setPasswordError(true)
    } else {
      setPasswordError(false)
    }
  }

  async function handleChangePassword() {
    setIsSaving(true)

    try {
      await api.changePassword({ old: oldPassword, password: newPassword, password_confirmation: confirmPassword })
      setOldPassword('')
      setNewPassword('')
      setConfirmPassword('')
      oldPasswordRef.current.value = ''
      newPasswordRef.current.value = ''
      confirmPasswordRef.current.value = ''
      toast.success('Password Changed Successfully', toastConfig)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, { ...toastConfig, autoClose: 8000 })
      setIsSaving(false)
    }
  }

  async function handleLogout($type = 'all') {
    await logoutDevice($type)    
    if ($type !== 'all') {
      setActivities((activities_) => (activities_.filter((a) => a.created_at !== $type)))
      toast.success('Device logged out successfully', toastConfig)
    }
  }

  const toastConfig = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  return (
    <div className="col-12 col-lg-8">

      <div className={classNames("card border-gray-300 p-0 p-md-4 mb-4", {
                        "bg-dark-card text-dark-white": theme === "dark"
                    })}>
        <div className="card-body">
          <h3 className={classNames("h5 mb-3", {
                        "bg-dark-card text-dark-white": theme === "dark"
                    })}>Change Password </h3>

          <div className="mb-4"><label htmlFor="inputPasswordOld">Current Password</label> <input type="password" ref={oldPasswordRef} className="form-control" onChange={(e) => setOldPassword(e.target.value)} required /></div>
          <div className="mb-4"><label htmlFor="inputPasswordNew">New Password</label> <input type="password" onBlur={validatePassword} ref={newPasswordRef} className="form-control" onChange={(e) => setNewPassword(e.target.value)} required /> <span className="form-text small text-muted">The password must be 8-20 characters, and must <em>not</em> contain spaces.</span></div>
          <div className="mb-4">
            <label htmlFor="inputPasswordNewVerify">Confirm Passowrd</label>
            <input type="password" onBlur={validatePassword} ref={confirmPasswordRef} className="form-control" onChange={(e) => setConfirmPassword(e.target.value)} required />
            <div className="form-text small text-muted">To confirm, type the new password again.</div>
            <div className="form-text small text-danger" style={{ display: passwordError ? 'block' : 'none' }}>Password must be atleast 6 characters.</div>
          </div>
          <div className="form-group"><button className="btn btn-dark" onClick={handleChangePassword} disabled={handleValidate}>Save</button></div>

        </div>
      </div>
      
      <div className={classNames("card card-body bg-white border-gray-300 p-0 p-md-4", {
                        "bg-dark-card text-dark-white": theme === "dark"
                    })}>
        <div className="card-header border-0">
          <div className="row d-block d-md-flex align-items-center">
            <div className="col">
              <h3 className={classNames("h5", {
                        "bg-dark-card text-dark-white": theme === "dark"
                    })}>Your Sessions</h3>
              <p className="small">This is a list of devices that have logged into your account.<br />Revoke any sessions that you do not recognize.</p>
            </div>
            <div className="col-auto"><button className="btn btn-sm btn-dark" onClick={() => {!isLoggingOut && handleLogout()}} disabled={isLoggingOut || isFetching}>Log out all</button></div>
          </div>
        </div>
        <div className="card-body p-md-0" style={{ maxHeight: '500px', overflow: 'auto' }}>
          <ul className="list-group list-group-flush">

            {
              isFetching ?
                Array.from(Array(3)).map((e, k) => (
                  <li className="list-group-item border-bottom rounded-0 py-4" key={k}>
                    <div className="row d-block d-md-flex align-items-center">
                      <div className="col-auto">
                        <div className="icon icon-lg me-md-5 fw-50"><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={50} height={50}/></div>
                      </div>
                      <div className="col ms-md-n5 my-4 my-md-0">
                        <h3 className="h6 mb-1"><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={150} /></h3>
                        <div className="small"><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={80} /></div><span className="small"><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={250} /></span>
                      </div>
                      <div className="col-auto"><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={50} /></div>
                    </div>
                  </li>
                )) :
                activities.length > 0 && activities.map((activity, k) => (
                  activity.location &&
                  (<li className="list-group-item border-bottom rounded-0 py-4" key={k}>
                    <div className={classNames("row d-block d-md-flex align-items-center", {
                        "text-dark-white": theme === "dark"
                    })}>
                      <div className="col-auto">
                        <div className="icon icon-lg me-md-5 fw-50"><span className={activity.platform === 'mobile' ? "fas fa-mobile-alt" : "fas fa-laptop"}></span></div>
                      </div>
                      <div className="col ms-md-n5 my-4 my-md-0">
                        <h3 className={classNames("h6 mb-1", {
                        "text-dark-white": theme === "dark"
                    })}>{activity.browser}</h3>
                        <div className={classNames("small")}>{activity.location.geoplugin_region}, {activity.location.geoplugin_countryName}</div><span className="small"><span className={classNames("text-dark fw-bold", {
                      "text-dark-white": theme === "dark"
                  })}>Last
                          accessed:</span> {activity.created_at}</span>
                      </div>
                      <div className="col-auto"><button className={classNames("btn btn-xs btn-outline-gray", {
                        "text-dark-white": theme === "dark"
                    })} onClick={() => {!isLoggingOut && handleLogout(activity.created_at)}}>Log out</button></div>
                    </div>
                  </li>)
                ))
            }

            {/* <li className="list-group-item py-4">
              <div className="row d-block d-md-flex align-items-center">
                <div className="col-auto">
                  <div className="icon icon-lg me-md-5 fw-50"><span className="fas fa-laptop"></span></div>
                </div>
                <div className="col ms-md-n5 my-4 my-md-0">
                  <h3 className="h6 mb-1">Chrome on Macintosh</h3>
                  <div className="small">New York, USA</div><span className="small"><span className="text-dark fw-bold">Last
                    accessed:</span> March 11 at 4:26PM</span>
                </div>
                <div className="col-auto"><button className="btn btn-xs btn-outline-gray">Log out</button></div>
              </div>
            </li> */}

          </ul>
        </div>
      </div>
    </div>
  )
}

export default Security