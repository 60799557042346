import React, { useState } from 'react'
import { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import UseAuth from '../hooks/UseAuth';
import * as api from '../services/api_service'
// import UseAuth from '../../hooks/UseAuth';
// import AppHelpers from '../../hooks/AppHook';

function NewTrade({ symbol }) {
    const { setUser, user } = UseAuth()
    // const { currencyFormat } = AppHelpers()
    const [show, setShow] = useState(false)
    const [orderType, setOrderType] = useState('')
    const [entry, setEntry] = useState('')
    const [amount, setAmount] = useState('')
    const [tp, setTp] = useState('')
    const [duration, setDuration] = useState('')
    const [isFetching, setIsFetching] = useState(false)
    const entryRef = useRef()
    const navigate = useNavigate()

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    async function handleAddTrade(direction) {
        setIsFetching(true)
        try {
            const resp = await api.trade(
                {
                    symbol: symbol[1],
                    order_type: orderType,
                    entry: entry,
                    amount: amount,
                    tp: tp,
                    duration: duration,
                    direction: direction,
                }
            )
            // setTrades((w) => [{...resp.data.data, status: 1}, ...w])
            if (user?.demo) {
                setUser((user_) => {
                    return { ...user_, demo_balance: user_.demo_balance - parseFloat(resp.data.data.amount) }
                })
            } else {
                setUser((user_) => {
                    return { ...user_, balance: user_.balance - parseFloat(resp.data.data.amount) }
                })
            }

            handleClose()
            setIsFetching(false)
            toast.success("Trade Placed Successfully", { theme: "dark" })
            navigate('/user/trade-history')
        } catch (error) {
            setIsFetching(false)
            toast.error(error.response.data.message, { theme: "dark" })
        }
    }

    function handleSetOrderType(data) {
        if (data !== 'limit order') {
            setEntry('')
            entryRef.current.value = ''
        }
        setOrderType(data)
    }

    function isDisabled() {
        return symbol === '' || orderType === '' || (orderType === 'limit order' && entry === '') || (orderType === 'limit order' && entry <= 0) || tp === '' || amount === '' || amount <= 0 || tp <= 0 || duration === '' || isFetching
    }

    return (
        <>
            <button onClick={handleShow} className='btn btn-primary w-100'>Trade {`${symbol[1]}`.toUpperCase()}</button>
            <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
                centered
                className='mt-n5'
            >
                <Modal.Body className='pt-4'>
                    <div className='col-12' style={{ 'padding': '5px 10px' }}>
                        <div className='text-left'>
                            <div style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', fontSize: '13px' }} className='bg-primary text-white'>Trader Panel</div><br />
                            {/* <div style={{ fontSize: '20px', marginLeft: 4, marginBottom: '10px' }}>{activePackage.name && (`${activePackage.name}`).toUpperCase()}</div> */}

                            <div>

                                <div className="mb-2 mt-3">
                                    <label htmlFor="symbolInput" style={{ fontSize: '13px' }}>Symbol:</label>
                                    <h2>{symbol[1]}</h2>
                                </div>

                                <div className="mb-2 mt-3">
                                    <label htmlFor="amountInput" style={{ fontSize: '13px' }}>Amount to trade ($)</label>
                                    <div className="input-group">
                                        <input type={'number'} className="form-control" placeholder='0.00' id="amountInput" onChange={(e) => setAmount(e.target.value)} style={{ fontSize: '13px' }} />
                                    </div>
                                </div>

                                <div className="mb-2 mt-3">
                                    <label htmlFor="tpInput" style={{ fontSize: '13px' }}>Take Profit %</label>
                                    <div className="input-group">
                                        <input type={'number'} className="form-control" placeholder='0' id="tpInput" onChange={(e) => setTp(e.target.value)} style={{ fontSize: '13px' }} />
                                    </div>
                                </div>

                                <div className="mb-2 mt-3">
                                    <label htmlFor="orderTypeInput" style={{ fontSize: '13px' }}>Execution Type</label>
                                    <div className="input-group">
                                        <select className="form-control" id="orderTypeInput" onChange={(e) => handleSetOrderType(e.target.value)} style={{ fontSize: '13px' }} >
                                            <option value=''>-- Select --</option>
                                            <option value="market execution">Market Execution</option>
                                            <option value="limit order">Limit Order</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-2 mt-3" style={{ 'display': orderType === 'limit order' ? 'block' : 'none' }}>
                                    <label htmlFor="entryInput" style={{ fontSize: '13px' }}>Entry Price</label>
                                    <div className="input-group">
                                        <input type={'number'} ref={entryRef} className="form-control" placeholder='0' id="entryInput" onChange={(e) => setEntry(e.target.value)} style={{ fontSize: '13px' }} />
                                    </div>
                                </div>

                                <div className="mb-2 mt-3">
                                    <label htmlFor="walletInput" style={{ fontSize: '12px' }}>Maximum Trade Duration</label>
                                    <div className="input-group">
                                        <select className="form-control" id="walletInput" onChange={(e) => setDuration(e.target.value)} style={{ fontSize: '13px' }} >
                                            <option value=''>-- Select --</option>
                                            <option value="1minute">1minute</option>
                                            <option value="2minutes">2minutes</option>
                                            <option value="3minutes">3minutes</option>
                                            <option value="5minutes">5minutes</option>
                                            <option value="15minutes">15minutes</option>
                                            <option value="30minutes">30minutes</option>
                                            <option value="45minutes">45minutes</option>
                                            <option value="1hour">1hour</option>
                                            <option value="5hours">5hours</option>
                                            <option value="1day">1day</option>
                                            <option value="2days">2days</option>
                                            <option value="5days">5days</option>
                                            <option value="1week">1week</option>
                                            <option value="2weeks">2weeks</option>
                                            <option value="1month">1month</option>
                                        </select>
                                    </div>
                                </div>

                                {/* <div className="mb-2 mt-3">
                                    <label htmlFor="walletInput" style={{ fontSize: '12px' }}>Select Currency</label>
                                    <div className="input-group">
                                        <select className="form-control" id="walletInput" onChange={(e) => setAccount(e.target.value)} style={{ fontSize: '13px' }} >
                                            <option value=''>-- Select Currency --</option>
                                            <option value="Bitcoin">Bitcoin</option>
                                            <option value="Ethereum">Ethereum</option>
                                            <option value="BNB">BNB</option>
                                            <option value="Litecoin">Litecoin</option>
                                            <option value="USDT (ERC20)">USDT (ERC20)</option>
                                            <option value="USDT (TRC20)">USDT (TRC20)</option>

                                        </select>
                                    </div>
                                </div> */}

                                <div className="d-flex mt-4">
                                    <button className='btn btn-danger' style={{ 'padding': '10px 40px', 'marginRight': '20px' }} onClick={() => handleAddTrade('sell')} disabled={isDisabled()}>Sell</button>
                                    <button className='btn btn-success' style={{ 'padding': '10px 40px', 'marginRight': '20px', 'color': '#fff' }} onClick={() => handleAddTrade('buy')} disabled={isDisabled()}>Buy</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NewTrade