import React from 'react'
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import UseAuth from '../hooks/UseAuth';

function ProfitChart({ investments }) {

    const { isAuthenticated } = UseAuth()
    const profits_ = []
    const getMonth = ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const activeMonths_ = []
    const activeMonthsObj = {}
    const labelMonth = []
    const labelMonthVal = []
    investments.forEach((e) => {
        e.profits.forEach((p) => {
            profits_.push(p)
        })
    })

    profits_.forEach((e) => {
        let d = new Date(e.created_at)
        let a = {}
        a.month = d.getMonth()
        a.amount = parseFloat(e.amount)

        activeMonths_.push(a)
    })

    activeMonths_.forEach((e) => {
        if (activeMonthsObj[e.month]) {
            activeMonthsObj[e.month] = activeMonthsObj[e.month] + e.amount
        } else {
            activeMonthsObj[e.month] = e.amount
        }
    })

    if (Object.keys(activeMonthsObj).length > 0) {

        for (let index = 1; index < Array(Math.max(...Object.keys(activeMonthsObj))).length + 1; index++) {

            labelMonth.push(getMonth[index])
            if (Object.keys(activeMonthsObj).includes(`${index}`)) {
                labelMonthVal.push(activeMonthsObj[index])
            } else {
                labelMonthVal.push(5)
            }

        }
    }

    const chartData = {
        labels: labelMonth,
        datasets: [{
            label: 'My First Dataset',
            data: labelMonthVal,
            fill: true,
            borderColor: '#893168',
            tension: 0.1
        }]
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
        }
    }

    // console.log(labelMonth)
    // console.log(labelMonthVal)

    return (
        <div style={{ width: '100%', height: 'auto', display: 'inline-block' }}>
            {isAuthenticated && <Chart type='line' options={chartOptions} data={chartData} />}
        </div>
    )
}

export default ProfitChart