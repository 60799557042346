import React, { useEffect, useMemo, useRef, useState } from 'react'
// import { Link } from 'react-router-dom'
import useAuth from '../../hooks/UseAuth';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import * as api from '../../services/api_service'
import NewWallet from '../../components/NewWallet';

import profilePic from '../../assets/image/profile/default.png'
import classNames from 'classnames';
import AppHelpers from '../../hooks/AppHook';

function Profile() {

    const {theme} = AppHelpers();
    const { user, setUser, isAuthenticated } = useAuth()
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('')
    const [country, setCountry] = useState('')
    const [address, setAddress] = useState('')
    const [wallets, setWallets] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const [isEditing, setIsEditing] = useState(false)
    const uploadImageRef = useRef()
    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    useEffect(() => {
        async function getAddresses() {
            try {
                const resp = await Promise.all([
                    api.addresses(),
                ]);
                setIsFetching(false)
                setWallets(resp[0].data.data)
            } catch (error) {
                setIsFetching(false)
                console.log(error)
            }
        }

        getAddresses()
    }, [])

    const handleValidate = useMemo(() => {
        return firstname === '' || lastname === '' || phone === '' || gender === '' || country === '' || address === '' || isEditing || !isAuthenticated ? true : false
    }, [firstname, lastname, phone, gender, country, address, isAuthenticated, isEditing])

    useEffect(() => {
        if (isAuthenticated) {
            setFirstname(user.firstname)
            setLastname(user.lastname)
            user.phone === null ? setPhone('') : setPhone(user.phone)
            setGender(user.gender)
            setCountry(user.country)
            user.address === null ? setAddress('') : setAddress(user.address)
        }
    }, [isAuthenticated, user])

    async function handleEdit() {
        setIsEditing(true)
        try {
            const resp = await api.editProfile({ firstname: firstname, lastname: lastname, phone: phone, gender: gender, country: country, address: address })
            setUser(resp.data.data)
            toast.success('Profile Updated Successfully', toastConfig)
            setIsEditing(false)
        } catch (error) {
            // console.log(error)
            if (error.response.status === 422) {
                toast.error(error.response.data.message, toastConfig)
            } else {
                toast.error('Error Updating Profile', toastConfig)
            }
            setIsEditing(false)
        }
    }

    const fileChangeHandler = async (e) => {
        const file_ = e.target.files[0];
        if (file_) {
            if (!file_.type.match(imageMimeType)) {
                alert("Image mime type is not valid");
                return;
            }
            setIsEditing(true)
            try {
                const resp = await api.editProfileImage({ file: file_ })
                setUser((user_) => ({ ...user_, image: resp.data.image }))
                uploadImageRef.current.value = ''
                toast.success('Profile Image Updated Successfully', toastConfig)
                setIsEditing(false)
            } catch (error) {
                if (error.response.status === 422) {
                    toast.error(error.response.data.message, toastConfig)
                } else {
                    toast.error('Error Updating Profile', toastConfig)
                }
                setIsEditing(false)
            }
        }
    }

    async function handleDeleteAddress(id) {
        let wallets_ = wallets.filter((e) => e.id !== id)
        setWallets(wallets_)
        try {
            await api.deleteAddress(id)
            toast.success('Address Deleted Successfully', toastConfig)
        } catch (error) {
            console.log(error)
        }
    }

    const toastConfig = {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
    }

    return (
        <div className="col-12 col-lg-8">
            <div className="row">
                <div className="col-lg-12">
                    <div className={classNames("card card-body bg-white border-gray-300 mb-4", {
                        "bg-dark-card text-dark-white": theme === "dark"
                    })}>
                        <h2 className={classNames("h5 mb-4", {
                            "text-dark-white": theme === "dark"
                        })}>General information</h2>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="mb-3"><label htmlFor="first_name">Firstname</label> <input className="form-control"
                                    id="first_name" type="text" defaultValue={isAuthenticated ? user.firstname : ''} onChange={(e) => setFirstname(e.target.value)} placeholder="Enter your first name" required /></div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="mb-3"><label htmlFor="last_name">Lastname</label> <input className="form-control"
                                    id="last_name" type="text" defaultValue={isAuthenticated ? user.lastname : ''} onChange={(e) => setLastname(e.target.value)} placeholder="Also your last name" required /></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="mb-3"><label htmlFor="email">Email</label> <input className="form-control" id="email"
                                    type="email" defaultValue={isAuthenticated ? user.email : ''} placeholder="name@company.com" readOnly /></div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="mb-3"><label htmlFor="phone">Phone</label> <input className="form-control" id="phone"
                                    type="number" defaultValue={isAuthenticated ? user.phone : ''} onChange={(e) => setPhone(e.target.value)} placeholder="Eg. +12-345 678 910" /></div>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6 mb-3"><label htmlFor="gender">Gender</label> <select onChange={(e) => setGender(e.target.value)} className="form-select" id="gender" aria-label="Default select example">
                                <option value="">-- SELECT --</option>
                                {isAuthenticated && user.gender === 'male' ? <option value={'male'} selected>Male</option> : <option value={'male'}>Male</option>}
                                {isAuthenticated && user.gender === 'female' ? <option value={'female'} selected>Female</option> : <option value={'female'}>Female</option>}
                                {isAuthenticated && user.gender === 'none' ? <option value={'none'} selected>Do not specify</option> : <option value={'none'}>Do not specify</option>}
                            </select></div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="country2">Country</label>
                                <select className="form-select" id="country2" onChange={(e) => setCountry(e.target.value)} aria-label="Default select example">
                                    <option value="">-- SELECT --</option>
                                    {isAuthenticated && user.country && <option selected>{user.country}</option>}
                                    <option>Afghanistan</option>
                                    <option>Albania</option>
                                    <option>Algeria</option>
                                    <option>American Samoa</option>
                                    <option>Andorra</option>
                                    <option>Angola</option>
                                    <option>Anguilla</option>
                                    <option>Antarctica</option>
                                    <option>Antigua And Barbuda</option>
                                    <option>Argentina</option>
                                    <option>Armenia</option>
                                    <option>Aruba</option>
                                    <option>Australia</option>
                                    <option>Austria</option>
                                    <option>Azerbaijan</option>
                                    <option>Bahamas The</option>
                                    <option>Bahrain</option>
                                    <option>Bangladesh</option>
                                    <option>Barbados</option>
                                    <option>Belarus</option>
                                    <option>Belgium</option>
                                    <option>Belize</option>
                                    <option>Benin</option>
                                    <option>Bermuda</option>
                                    <option>Bhutan</option>
                                    <option>Bolivia</option>
                                    <option>Bosnia and Herzegovina</option>
                                    <option>Botswana</option>
                                    <option>Bouvet Island</option>
                                    <option>Brazil</option>
                                    <option>British Indian Ocean Territory</option>
                                    <option>Brunei</option>
                                    <option>Bulgaria</option>
                                    <option>Burkina Faso</option>
                                    <option>Burundi</option>
                                    <option>Cambodia</option>
                                    <option>Cameroon</option>
                                    <option>Canada</option>
                                    <option>Cape Verde</option>
                                    <option>Cayman Islands</option>
                                    <option>Central African Republic</option>
                                    <option>Chad</option>
                                    <option>Chile</option>
                                    <option>China</option>
                                    <option>Christmas Island</option>
                                    <option>Cocos (Keeling) Islands</option>
                                    <option>Colombia</option>
                                    <option>Comoros</option>
                                    <option>Cook Islands</option>
                                    <option>Costa Rica</option>
                                    <option>Cote D&#039;Ivoire (Ivory Coast)</option>
                                    <option>Croatia (Hrvatska)</option>
                                    <option>Cuba</option>
                                    <option>Cyprus</option>
                                    <option>Czech Republic</option>
                                    <option>Democratic Republic Of The Congo</option>
                                    <option>Denmark</option>
                                    <option>Djibouti</option>
                                    <option>Dominica</option>
                                    <option>Dominican Republic</option>
                                    <option>East Timor</option>
                                    <option>Ecuador</option>
                                    <option>Egypt</option>
                                    <option>El Salvador</option>
                                    <option>Equatorial Guinea</option>
                                    <option>Eritrea</option>
                                    <option>Estonia</option>
                                    <option>Ethiopia</option>
                                    <option>External Territories of Australia</option>
                                    <option>Falkland Islands</option>
                                    <option>Faroe Islands</option>
                                    <option>Fiji Islands</option>
                                    <option>Finland</option>
                                    <option>France</option>
                                    <option>French Guiana</option>
                                    <option>French Polynesia</option>
                                    <option>French Southern Territories</option>
                                    <option>Gabon</option>
                                    <option>Gambia The</option>
                                    <option>Georgia</option>
                                    <option>Germany</option>
                                    <option>Ghana</option>
                                    <option>Gibraltar</option>
                                    <option>Greece</option>
                                    <option>Greenland</option>
                                    <option>Grenada</option>
                                    <option>Guadeloupe</option>
                                    <option>Guam</option>
                                    <option>Guatemala</option>
                                    <option>Guernsey and Alderney</option>
                                    <option>Guinea</option>
                                    <option>Guinea-Bissau</option>
                                    <option>Guyana</option>
                                    <option>Haiti</option>
                                    <option>Heard and McDonald Islands</option>
                                    <option>Honduras</option>
                                    <option>Hong Kong S.A.R.</option>
                                    <option>Hungary</option>
                                    <option>Iceland</option>
                                    <option>India</option>
                                    <option>Indonesia</option>
                                    <option>Iran</option>
                                    <option>Iraq</option>
                                    <option>Ireland</option>
                                    <option>Israel</option>
                                    <option>Italy</option>
                                    <option>Jamaica</option>
                                    <option>Japan</option>
                                    <option>Jersey</option>
                                    <option>Jordan</option>
                                    <option>Kazakhstan</option>
                                    <option>Kenya</option>
                                    <option>Kiribati</option>
                                    <option>Korea North</option>
                                    <option>Korea South</option>
                                    <option>Kuwait</option>
                                    <option>Kyrgyzstan</option>
                                    <option>Laos</option>
                                    <option>Latvia</option>
                                    <option>Lebanon</option>
                                    <option>Lesotho</option>
                                    <option>Liberia</option>
                                    <option>Libya</option>
                                    <option>Liechtenstein</option>
                                    <option>Lithuania</option>
                                    <option>Luxembourg</option>
                                    <option>Macau S.A.R.</option>
                                    <option>Macedonia</option>
                                    <option>Madagascar</option>
                                    <option>Malawi</option>
                                    <option>Malaysia</option>
                                    <option>Maldives</option>
                                    <option>Mali</option>
                                    <option>Malta</option>
                                    <option>Man (Isle of)</option>
                                    <option>Marshall Islands</option>
                                    <option>Martinique</option>
                                    <option>Mauritania</option>
                                    <option>Mauritius</option>
                                    <option>Mayotte</option>
                                    <option>Mexico</option>
                                    <option>Micronesia</option>
                                    <option>Moldova</option>
                                    <option>Monaco</option>
                                    <option>Mongolia</option>
                                    <option>Montserrat</option>
                                    <option>Morocco</option>
                                    <option>Mozambique</option>
                                    <option>Myanmar</option>
                                    <option>Namibia</option>
                                    <option>Nauru</option>
                                    <option>Nepal</option>
                                    <option>Netherlands Antilles</option>
                                    <option>Netherlands The</option>
                                    <option>New Caledonia</option>
                                    <option>New Zealand</option>
                                    <option>Nicaragua</option>
                                    <option>Niger</option>
                                    <option>Nigeria</option>
                                    <option>Niue</option>
                                    <option>Norfolk Island</option>
                                    <option>Northern Mariana Islands</option>
                                    <option>Norway</option>
                                    <option>Oman</option>
                                    <option>Pakistan</option>
                                    <option>Palau</option>
                                    <option>Palestinian Territory Occupied</option>
                                    <option>Panama</option>
                                    <option>Papua new Guinea</option>
                                    <option>Paraguay</option>
                                    <option>Peru</option>
                                    <option>Philippines</option>
                                    <option>Pitcairn Island</option>
                                    <option>Poland</option>
                                    <option>Portugal</option>
                                    <option>Puerto Rico</option>
                                    <option>Qatar</option>
                                    <option>Republic Of The Congo</option>
                                    <option>Reunion</option>
                                    <option>Romania</option>
                                    <option>Russia</option>
                                    <option>Rwanda</option>
                                    <option>Saint Helena</option>
                                    <option>Saint Kitts And Nevis</option>
                                    <option>Saint Lucia</option>
                                    <option>Saint Pierre and Miquelon</option>
                                    <option>Saint Vincent And The Grenadines</option>
                                    <option>Samoa</option>
                                    <option>San Marino</option>
                                    <option>Sao Tome and Principe</option>
                                    <option>Saudi Arabia</option>
                                    <option>Senegal</option>
                                    <option>Serbia</option>
                                    <option>Seychelles</option>
                                    <option>Sierra Leone</option>
                                    <option>Singapore</option>
                                    <option>Slovakia</option>
                                    <option>Slovenia</option>
                                    <option>Smaller Territories of the UK</option>
                                    <option>Solomon Islands</option>
                                    <option>Somalia</option>
                                    <option>South Africa</option>
                                    <option>South Georgia</option>
                                    <option>South Sudan</option>
                                    <option>Spain</option>
                                    <option>Sri Lanka</option>
                                    <option>Sudan</option>
                                    <option>Suriname</option>
                                    <option>Svalbard And Jan Mayen Islands</option>
                                    <option>Swaziland</option>
                                    <option>Sweden</option>
                                    <option>Switzerland</option>
                                    <option>Syria</option>
                                    <option>Taiwan</option>
                                    <option>Tajikistan</option>
                                    <option>Tanzania</option>
                                    <option>Thailand</option>
                                    <option>Togo</option>
                                    <option>Tokelau</option>
                                    <option>Tonga</option>
                                    <option>Trinidad And Tobago</option>
                                    <option>Tunisia</option>
                                    <option>Turkey</option>
                                    <option>Turkmenistan</option>
                                    <option>Turks And Caicos Islands</option>
                                    <option>Tuvalu</option>
                                    <option>Uganda</option>
                                    <option>Ukraine</option>
                                    <option>United Arab Emirates</option>
                                    <option>United Kingdom</option>
                                    <option>United States</option>
                                    <option>United States Minor Outlying Islands</option>
                                    <option>Uruguay</option>
                                    <option>Uzbekistan</option>
                                    <option>Vanuatu</option>
                                    <option>Vatican City State (Holy See)</option>
                                    <option>Venezuela</option>
                                    <option>Vietnam</option>
                                    <option>Virgin Islands (British)</option>
                                    <option>Virgin Islands (US)</option>
                                    <option>Wallis And Futuna Islands</option>
                                    <option>Western Sahara</option>
                                    <option>Yemen</option>
                                    <option>Yugoslavia</option>
                                    <option>Zambia</option>
                                    <option>Zimbabwe</option>
                                </select>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-12 mb-3">
                                <div className="mb-3"><label htmlFor="address">Address</label> <input onChange={(e) => setAddress(e.target.value)} className="form-control" id="address"
                                    type="text" defaultValue={isAuthenticated ? user.address : ''} placeholder="Enter your home address" /></div>
                            </div>
                        </div>

                        <div className="mt-2"><button onClick={handleEdit} className="btn btn-primary" disabled={handleValidate}>Save All</button></div>
                    </div>
                    <div className={classNames("card card-body bg-white border-gray-300 mb-4", {
                        "bg-dark-card text-dark-white": theme === "dark"
                    })}>
                        <h2 className={classNames("h5 mb-4", {
                            "text-dark-white": theme === "dark"
                        })}>Select profile photo</h2>
                        <div className="row align-items-center">
                            <div className="col-lg-1 mb-2 mb-lg-0">
                                <div className="avatar-lg"><div className="rounded-circle profile-image-display-div"
                                    style={{ backgroundImage: isAuthenticated ? `url(${user.image})` : `url(${profilePic})` }} alt="change avatar" /></div>
                            </div>
                            <div className="col-lg-6 mb-3 mb-lg-0">
                                <div className={classNames("h6 mb-0 ms-lg-3", {
                            "text-dark-white": theme === "dark"
                        })}>Your avatar</div><small className={classNames("text-gray ms-lg-3", {
                            "text-dark-white": theme === "dark"
                        })}>JPG, GIF or PNG.
                                    Max size of 10M</small>
                            </div>
                            <div className="col-lg-5">
                                <div className="mb-3"><label htmlFor="formFile" className="form-label">Default file input example</label>
                                    <input className="form-control" ref={uploadImageRef} onChange={fileChangeHandler} type="file" accept='.png, .jpg, .jpeg' /></div>
                            </div>
                        </div>
                    </div>
                    <div className={classNames("card card-body bg-white border-gray-300", {
                            "bg-dark-card": theme === "dark"
                        })}>
                        <div className="row">
                            <div className="col-6 text-left"><h2 className={classNames("h5 mb-4", {
                                "text-dark-white": theme === "dark"
                            })}>My Withdrawal Addresses</h2></div>
                            <div className="col-6 text-right">
                                <NewWallet setWallets={setWallets} />
                            </div>
                        </div>

                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr className={classNames({
                                    "text-dark-white": theme === "dark"
                                })}>
                                        <th>Currency</th>
                                        <th>Address</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isFetching ?
                                            Array.from(Array(4)).map((e, k) => (
                                                <tr key={k} className={classNames({
                                                    "text-dark-white": theme === "dark"
                                                })}>
                                                    <td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={150} /></td>
                                                    <td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={150} /></td>
                                                    <td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} width={30} /></td>
                                                </tr>
                                            )) :
                                            wallets.length > 0 && wallets.map((wallet, k) => (
                                                <tr key={k} className={classNames({
                                                    "text-dark-white": theme === "dark"
                                                })}>
                                                    <td>{wallet.account}</td>
                                                    <td>{wallet.address}</td>
                                                    <td><button className='btn btn-link' onClick={() => handleDeleteAddress(wallet.id)}><i className='fa fa-trash text-danger' /></button></td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile