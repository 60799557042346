import React, { useEffect, useState, useMemo } from 'react'
import * as api from '../../services/api_service'
import NewTrade from '../../components/NewTrade';

function Trade() {

  const [symbols, setSymbols] = useState([])
  const [isFetchingSymbols, setisFetchingSymbols] = useState(true)
  const [group, setGroup] = useState('stock')
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    async function getSymbols() {
      try {
        const resp = await api.symbols();
        console.log('all', resp.data)
        setisFetchingSymbols(false)
        setSymbols(resp.data)
      } catch (error) {
        setisFetchingSymbols(false)
        console.log(error)
      }
    }

    getSymbols()

  }, [])

  const stock = useMemo(() => {
    if (group === 'stock' && !isFetchingSymbols) {
      if (keyword.length < 1) {
        return symbols.stock
      }
      let s_ = symbols.stock.filter((e) => (e[1].toLowerCase().indexOf(keyword) > -1))
      return s_
    }else {
      return []
    }
  }, [symbols, group, keyword])

  const crypto = useMemo(() => {
    if (group === 'crypto' && !isFetchingSymbols) {
      if (keyword.length < 1) {
        return symbols.crypto
      }
      let s_ = symbols.crypto.filter((e) => (e[1].toLowerCase().indexOf(keyword) > -1))
      return s_
    }else {
      return []
    }
  }, [symbols, group, keyword])

  const futures = useMemo(() => {
    if (group === 'futures' && !isFetchingSymbols) {
      if (keyword.length < 1) {
        return symbols.futures
      }
      let s_ = symbols.futures.filter((e) => (e[1].toLowerCase().indexOf(keyword) > -1))
      return s_
    }else {
      return []
    }
  }, [symbols, group, keyword])

  const cfd = useMemo(() => {
    if (group === 'cfd' && !isFetchingSymbols) {
      if (keyword.length < 1) {
        return symbols.cfd
      }
      let s_ = symbols.cfd.filter((e) => (e[1].toLowerCase().indexOf(keyword) > -1))
      return s_
    }else {
      return []
    }
  }, [symbols, group, keyword])

  const forex = useMemo(() => {
    if (group === 'forex' && !isFetchingSymbols) {
      if (keyword.length < 1) {
        return symbols.forex
      }
      let s_ = symbols.forex.filter((e) => (e[1].toLowerCase().indexOf(keyword) > -1))
      return s_
    }else {
      return []
    }
  }, [symbols, group, keyword])

  return (
    <div className="col-12 col-lg-8">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body bg-white border-gray-300 mb-4">
            <h3 className="h5 mb-3" style={{ 'borderBottom': 'solid 2px #000', 'padding': '0px 10px 10px 5px', 'display': 'inline-block', 'width': '120px' }}>New Trade </h3>

            <div className='d-flex justify-content-between mb-3' >
              <div className='symbol-group'>
                <button onClick={() => setGroup('stock')} className={group === 'stock' ? "btn btn-sm btn-primary" : "btn btn-sm btn-white"}>Stock</button>
                <button onClick={() => setGroup('crypto')} className={group === 'crypto' ? "btn btn-sm btn-primary" : "btn btn-sm btn-white"}>Crypto</button>
                <button onClick={() => setGroup('futures')} className={group === 'futures' ? "btn btn-sm btn-primary" : "btn btn-sm btn-white"}>Futures</button>
                <button onClick={() => setGroup('cfd')} className={group === 'cfd' ? "btn btn-sm btn-primary" : "btn btn-sm btn-white"}>CFD</button>
                <button onClick={() => setGroup('forex')} className={group === 'forex' ? "btn btn-sm btn-primary" : "btn btn-sm btn-white"}>Forex</button>
              </div>
              {/* <div className='select-symbol-header'>
                <b>Select Symbol</b>
              </div> */}
            </div>

            <div className='mb-3'>
              <input type="text" onChange={(e) => setKeyword(e.target.value)} className='form-control' placeholder={`Search ${group}`} />
              {keyword.length > 0 && <b>"Search keyword: {keyword}"</b>}
            </div>

            {
              !isFetchingSymbols ?
                <>
                  <div className='m-1' style={{ display: group === 'stock' ? 'block' : 'none' }}>

                    <div className="row" style={{ 'maxHeight': '700px', 'overflow': 'auto' }}>
                      {
                        stock.map(symbol => (
                          <div className="col-md-4 mb-2">
                            <div className='shadow-sm p-3'>
                              <div className='symbol-card-chart'>
                                {/* <span style={{'position': 'absolute'}}>hello</span> */}
                                <iframe style={{ 'padding': '0px', 'margin': '0px', 'display': 'block', 'lineHeight': 0 }} src={`${process.env.REACT_APP_HOME_URL}/charts/asset-mini/${symbol[1]}/light`} frameBorder="0" width={'100%'} height={'auto'} title='chart'></iframe>
                              </div>
                              <NewTrade symbol={symbol} />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <div className='m-1' style={{ display: group === 'crypto' ? 'block' : 'none' }}>

                    <div className="row" style={{ 'maxHeight': '700px', 'overflow': 'auto' }}>
                      {
                        crypto.map(symbol => (
                          <div className="col-md-4 mb-2">
                            <div className='shadow-sm p-3'>
                              <div className='symbol-card-chart'>
                                {/* <span style={{'position': 'absolute'}}>hello</span> */}
                                <iframe style={{ 'padding': '0px', 'margin': '0px', 'display': 'block', 'lineHeight': 0 }} src={`${process.env.REACT_APP_HOME_URL}/charts/asset-mini/${symbol[1]}/light`} frameBorder="0" width={'100%'} height={'auto'} title='chart'></iframe>
                              </div>
                              <NewTrade symbol={symbol} />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <div className='m-1' style={{ display: group === 'futures' ? 'block' : 'none' }}>

                    <div className="row" style={{ 'maxHeight': '700px', 'overflow': 'auto' }}>
                      {
                        futures.map(symbol => (
                          <div className="col-md-4 mb-2">
                            <div className='shadow-sm p-3'>
                              <div className='symbol-card-chart'>
                                {/* <span style={{'position': 'absolute'}}>hello</span> */}
                                <iframe style={{ 'padding': '0px', 'margin': '0px', 'display': 'block', 'lineHeight': 0 }} src={`${process.env.REACT_APP_HOME_URL}/charts/asset-mini/${symbol[1]}/light`} frameBorder="0" width={'100%'} height={'auto'} title='chart'></iframe>
                              </div>
                              <NewTrade symbol={symbol} />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <div className='m-1' style={{ display: group === 'cfd' ? 'block' : 'none' }}>

                    <div className="row" style={{ 'maxHeight': '700px', 'overflow': 'auto' }}>
                      {
                        cfd.map(symbol => (
                          <div className="col-md-4 mb-2">
                            <div className='shadow-sm p-3'>
                              <div className='symbol-card-chart'>
                                {/* <span style={{'position': 'absolute'}}>hello</span> */}
                                <iframe style={{ 'padding': '0px', 'margin': '0px', 'display': 'block', 'lineHeight': 0 }} src={`${process.env.REACT_APP_HOME_URL}/charts/asset-mini/${symbol[1]}/light`} frameBorder="0" width={'100%'} height={'auto'} title='chart'></iframe>
                              </div>
                              <NewTrade symbol={symbol} />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <div className='m-1' style={{ display: group === 'forex' ? 'block' : 'none' }}>

                    <div className="row" style={{ 'maxHeight': '700px', 'overflow': 'auto' }}>
                      {
                        forex.map(symbol => (
                          <div className="col-md-4 mb-2">
                            <div className='shadow-sm p-3'>
                              <div className='symbol-card-chart'>
                                {/* <span style={{'position': 'absolute'}}>hello</span> */}
                                <iframe style={{ 'padding': '0px', 'margin': '0px', 'display': 'block', 'lineHeight': 0 }} src={`${process.env.REACT_APP_HOME_URL}/charts/asset-mini/${symbol[1]}/light`} frameBorder="0" width={'100%'} height={'auto'} title='chart'></iframe>
                              </div>
                              <NewTrade symbol={symbol} />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </> :
                <>
                  <div className='w-100 mt-5' style={{ 'textAlign': 'center', 'height': '400px' }}>
                    <span>
                      <i className='fa fa-spinner fa-spin'></i><br />
                      <span>Loading symbols</span>
                    </span>
                  </div>
                </>
            }

          </div>
        </div>
      </div>
    </div>
  )

}

export default Trade