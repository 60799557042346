import React, { useEffect, useMemo, useState } from 'react'
import AppHelpers from '../hooks/AppHook';
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames';

function DownlineEarnings({ earnings, paginate = 50, isFetchingEarnings }) {

    const { currencyFormat, ucfirst, theme } = AppHelpers()
    const [filteredEarnings, setFilteredEarnings] = useState([])
    const [paginatedEarnings, setPaginatedEarnings] = useState([])
    const [sort, setSort] = useState('all')
    const [activePage, setActivePage] = useState(0)

    useEffect(() => {
        setFilteredEarnings(earnings)
        setPaginatedEarnings(earnings.slice(0, paginate))
    }, [earnings, paginate])

    useEffect(() => {
        setPaginatedEarnings(filteredEarnings.slice(0, paginate))
    }, [filteredEarnings, paginate])

    const allPages = useMemo(() => {
        return Math.ceil(filteredEarnings.length / paginate)
    }, [filteredEarnings, paginate])

    function handleSort(data) {
        if (data === 'month') {
            let now = new Date()

            let filter_ = earnings.filter((e) => {
                let newDate = new Date(e.created_at)
                return newDate.getMonth() === now.getMonth() && newDate.getFullYear() === now.getFullYear()
            })

            setFilteredEarnings(filter_)
        } else {
            setFilteredEarnings(earnings)
        }
        setSort(data)
        setActivePage(0)
    }

    function handlePaginate(data) {
        let newPageStart = data * paginate
        setPaginatedEarnings(filteredEarnings.slice(newPageStart, newPageStart + paginate))
        setActivePage(data)
    }


    return (
        <div className={classNames("card border-gray-300", {
            "bg-dark-card text-dark-white": theme === "dark"
        })}>
            <div className="card-body d-flex flex-row align-items-center flex-0 border-bottom">
                <div className="d-block">
                    <div className={classNames("h5 fw-normal text-gray mt-2", {
                        "text-dark-white": theme === "dark"
                    })}>Earnings</div>
                    <small>Earnings From Downlines</small>
                </div>
                <div className="d-flex ms-auto">
                    <button className={sort === 'month' ? 'btn btn-sm me-3 btn-tertiary' : 'btn border-gray-300 btn-sm me-3 btn-white'} onClick={() => handleSort('month')}>Month</button>
                    <button className={sort === 'all' ? 'btn btn-sm me-3 btn-tertiary' : 'btn border-gray-300 btn-sm me-3 btn-white'} onClick={() => handleSort('all')}>See All</button>
                </div>
            </div>
            <div className="card-body">
                {/* <SkeletonTheme baseColor="#eee" highlightColor="#999">
                                <p>
                                    <Skeleton count={3} />
                                </p>
                            </SkeletonTheme> */}
                {
                    paginatedEarnings.length > 0 ?
                        <div style={{ width: '100%', height: 'auto' }}>
                            <div className="table-responsive">
                                <table className='table'>
                                    <thead>
                                        <tr className={classNames({
                                            "text-dark-white": theme === "dark"
                                        })}>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Amount</th>
                                            <th>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            paginatedEarnings.map((e, k) => (
                                                <tr key={k} className={classNames({
                                                    "text-dark-white": theme === "dark"
                                                })}>
                                                    <td>{e.created_at}</td>
                                                    <td>{ucfirst(e.user.firstname)} {ucfirst(e.user.lastname)}</td>
                                                    <td>{currencyFormat(e.amount)}</td>
                                                    <td>{e.user.email}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {allPages > 0 && filteredEarnings.length > paginate &&
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center mt-5">
                                        <li className="page-item"><button className="page-link" onClick={() => activePage > 0 && handlePaginate(activePage - 1)}>Previous</button></li>
                                        {
                                            Array.from(Array(allPages)).map((e, k) => (
                                                <li key={k} className={activePage === k ? 'page-item active' : 'page-item'} ><button className="page-link" onClick={() => handlePaginate(k)}>{k + 1}</button></li>
                                            ))
                                        }
                                        <li className="page-item"><button className="page-link" onClick={() => activePage + 1 < allPages && handlePaginate(activePage + 1)}>Next</button></li>

                                    </ul>
                                </nav>
                            }
                        </div> : isFetchingEarnings ? <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr className={classNames({
                                        "text-dark-white": theme === "dark"
                                    })}>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Amount</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(Array(4)).map((e, k) => (
                                        <tr key={k}>
                                            <td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td><td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td>
                                            <td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td><td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> :
                            <div>
                                <p>No result found</p>
                            </div>
                }
            </div>
        </div>


    )
}

export default DownlineEarnings