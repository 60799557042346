import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import * as api from '../../services/api_service'
import AppHelpers from '../../hooks/AppHook'

// import solarDark from '../../assets/image/chat/solarDark.jpg'
// import solarBlue from '../../assets/image/chat/solarBlue.jpg'
import chatDefault from '../../assets/image/chat/chatDefault.jpg'
import defaultAvatar from '../../assets/image/profile/default.png'
import classNames from 'classnames'
// import chatDefault2 from '../../assets/image/chat/chatDefault2.jpg'
// import chatDefaultDark from '../../assets/image/chat/chatDefaultDark.png'
// import chatDefaultAsh from '../../assets/image/chat/chatDefaultAsh.jpg'

function TicketMessage() {

  const { ucfirst, theme } = AppHelpers()
  const { id } = useParams()
  const navigate = useNavigate()
  const msgDivRef = useRef()
  const uploadImageRef = useRef()
  const ticketMsgRef = useRef()
  const [ticket, setTicket] = useState(null)
  const [messages, setMessages] = useState([])
  const [file, setFile] = useState(null)
  const [isFetching, setIsFetching] = useState(true)
  const [isSending, setIsSending] = useState(false)
  const [ticketMsg, setTicketMsg] = useState('')

  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  useEffect(() => {
    async function fetchDatas() {
      try {
        const resp = await api.ticket(id)
        console.log(resp.data.data)
        setTicket(resp.data.data)
        setMessages(resp.data.data.messages)
        setIsFetching(false)
      } catch (error) {
        navigate('/user/tickets')
      }
    }

    fetchDatas()
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  }, [])

  useEffect(() => {
    // msgDivRef.current.scrollIntoView({
    //   top: msgDivRef.current.scrollHeight,
    //   behavior: 'smooth',
    // })

    msgDivRef.current.scrollTo({
      top: msgDivRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }, [messages, msgDivRef])

  const fileChangeHandler = async (e) => {
    const file_ = e.target.files[0];
    if (file_) {
      if (!file_.type.match(imageMimeType)) {
        alert("Image mime type is not valid");
        return;
      }
      setFile(file_)
    } else {
      setFile(null)
    }
  }

  async function handleSend() {
    setIsSending(true)
    try {
      const resp = await api.sendTicketMessage({ message: ticketMsg, image: file, t_id: id })
      // console.log(resp.data.data)
      setMessages((msg_) => ([...msg_, resp.data.data]))
      uploadImageRef.current.value = ''
      ticketMsgRef.current.value = ''
      setIsSending(false)
    } catch (error) {
      console.log(error)
      setIsSending(false)
    }
  }

  return (
    <div className="col-12 col-lg-8">
      <div className={classNames("text-left mb-3", {
                        "text-dark-white": theme === "dark"
                    })} onClick={() => navigate('/user/tickets')} style={{ cursor: 'pointer', display: 'inline-block' }}><span className="icon icon-xs"><span
        className="fas fa-chevron-left me-2"></span></span> Back to tickets</div>
      <div className={classNames("card bg-gray-200-indigo border-gray-300 mb-4", {
                        "bg-dark-card text-dark-white": theme === "dark"
                    })}>
        <div className="row g-0 align-items-center">

          <div className="col-12 col-lg-12 col-xl-8">
            <div className="card-body text-dark">
              <div className={classNames('h5 mb-4', {
                        "text-dark-white": theme === "dark"
                    })}>Ticket {isFetching ? <Skeleton width={100} /> : `#${ticket.ticket_id}`}</div>
              <h4 className={classNames("h6", {
                        "text-dark-white": theme === "dark"
                    })}>{isFetching ? <Skeleton width={200} /> : ticket.title}</h4>

              {/* <ul className="list-group mb-3">
                <li className="list-group-item small p-0 border-0"><span className="fas fa-map-marker-alt me-2"></span>New
                  York, Manhattan, USA</li>
              </ul> */}
              <div className="row justify-content-between mt-3">
                <div className="col-4"><span className={classNames("small d-block", {
                  "text-dark-white": theme === "dark"
                })}><i className='fa fa-clock' /></span> <span
                  className={classNames("h6 text-dark fw-bold", {
                    "text-dark-white": theme === "dark"
                  })}>{isFetching ? <Skeleton width={100} /> : ticket.created_at}</span></div>
                <div className="col-4"><span className={classNames("small d-block", {
                  "text-dark-white": theme === "dark"
                })}>Ticket ID</span> <span
                  className={classNames("h6 text-dark fw-bold", {
                    "text-dark-white": theme === "dark"
                  })}>{isFetching ? <Skeleton width={100} /> : `#${ticket.ticket_id}`}</span></div>
                <div className="col-4"><span className={classNames("small d-block", {
                  "text-dark-white": theme === "dark"
                })}>Status</span> <span className={classNames("h6 text-dark fw-bold", {
                  "text-dark-white": theme === "dark"
                })}>
                  {isFetching ? <Skeleton width={100} /> : ticket.status === 1 ? 'Opened' : 'Closed'}
                </span></div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='ticket-message-div' style={{ backgroundImage: `url(${chatDefault})` }} ref={msgDivRef}>
        {
          !isFetching && ticket !== null && messages.length > 0 ?
            messages.map((msg, k) => (
              msg.from === 0 ?
                <div key={k}>
                  <div className="card bg-white border-gray-300 p-4 mb-4" >
                    <div className="d-flex justify-content-between align-items-center mb-2"><span className="font-small"><img className="avatar-sm img-fluid rounded-circle me-2"
                      src={defaultAvatar} alt="avatar" /> <span className="fw-bold">
                        Support</span> <span className="ms-2">{msg.created_at}</span></span>
                      <div className="d-none d-sm-block"><button className="btn btn-link text-dark" aria-label="phone"
                        data-toggle="tooltip" data-placement="top" title="Sent from the phone "
                        data-original-title="Sent from the phone"><span className="fas fa-mobile-alt"></span></button></div>
                    </div>
                    <p className="m-0">{msg.message}</p>
                  </div>

                  {msg.image &&
                    <div style={{ marginTop: '-20px', marginBottom: '15px', 'textAlign': 'left' }}>
                      <a href={msg.image} target='blank'>
                        <img src={msg.image} style={{ height: '100px' }} alt='FILE' />
                      </a>
                    </div>
                  }
                </div> :

                <div key={k}>
                  <div className="card bg-primary text-white border-gray-300 p-4 ms-md-5 ms-lg-6 mb-4" key={k}>
                    <div className="d-flex justify-content-between align-items-center mb-2"><span className="font-small"><span
                      className="fw-bold">{ucfirst(msg.from_user.firstname)} {ucfirst(msg.from_user.lastname)}</span> <span className="ms-2">{msg.created_at}</span></span>
                      <div className="d-none d-sm-block"><button className="btn btn-link text-white" aria-label="phone"
                        data-toggle="tooltip" data-placement="top" title="Sent from the phone "
                        data-original-title="Sent from the phone"><span className="fas fa-mobile-alt"></span></button></div>
                    </div>
                    <p className="m-0">{msg.message}</p>
                  </div>

                  {msg.image &&
                    <div style={{ marginTop: '-20px', marginBottom: '15px', 'textAlign': 'right' }}>
                      <a href={msg.image} target='blank'>
                        <img src={msg.image} style={{ height: '100px' }} alt='FILE' />
                      </a>
                    </div>
                  }
                </div>

            )) :
            <>
              <div>Loading</div>
            </>
        }
      </div>

      <div className="mt-4">

        <textarea onChange={(e) => setTicketMsg(e.target.value)} ref={ticketMsgRef} className="form-control border border-gray-300-gray mb-2" id="message"
          placeholder="Your Message" rows="3" maxLength="1000" required></textarea>

        <div className="row">
          <div className="col-8">
            <input className="form-control" ref={uploadImageRef} onChange={fileChangeHandler} type="file" accept='.png, .jpg, .jpeg' />
            <div className="text-gray small me-3 me-md-11">Supported files are: jpg, jpeg, png</div>
          </div>

          <div className="col-4" style={{ textAlign: 'right' }}>
            <button type="submit" className="btn btn-dark" onClick={handleSend} disabled={isSending || ticketMsg.length < 1 ? true : false}>Send &nbsp; <i className='fa fa-paper-plane' /></button>
          </div>
        </div>


      </div>

    </div >
  )
}

export default TicketMessage