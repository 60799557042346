import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import UseAuth from '../../hooks/UseAuth'
import * as api from '../../services/api_service'
import copytrade from '../../assets/image/illus/copytrade.gif'
import AppHelpers from '../../hooks/AppHook';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

function CopyTrader() {

  const { isAuthenticated } = UseAuth()
  const navigate = useNavigate()
  const { currencyFormat, ucfirst, theme } = AppHelpers()
  const [traders, setTraders] = useState([])
  const [traderInput, setTraderInput] = useState('')
  const [isFetching, setIsFetching] = useState(true)
  const [isFetchingTrader, setIsFetchingTrader] = useState(false)

  useEffect(() => {
    async function getTraders() {
      try {
        const resp = await api.subscriptions()
        setTraders(resp.data.data)
        setIsFetching(false)
      } catch (error) {
        console.log(error)
        setIsFetching(false)
      }
    }

    getTraders()
  }, [])

  async function getTrader() {
    setIsFetchingTrader(true)
    try {
      const resp = await api.trader(traderInput)
      navigate(`/user/copy-trader/${resp.data.data.ref}`)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, { ...toastConfig, autoClose: 8000 })
      setIsFetchingTrader(false)
    }
  }

  const toastConfig = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  return (
    <div className="col-12 col-lg-8">

      <div className="row">
        <div className="col-lg-12">
          <div className={classNames("card border-gray-300 p-0 p-md-4 mb-4 ", {
            "bg-dark-card": theme === "dark"
          })} >
            <div className="card-body">
              <h3 className={classNames("h5 mb-3", {
                "text-dark-white": theme === "dark"
              })} style={{ 'borderBottom': 'solid 3px #000', 'padding': '0px 10px 10px 5px', 'display': 'inline-block' }}>Copy Trader </h3>

              <div className="row">

                <div className="col-md-6 lg-card-image mb-5" style={{ 'backgroundImage': `url(${copytrade})` }}>
                </div>

                <div className="col-md-6 copy-trader-info">
                  <div className="centered-container" >
                    <div className={classNames('centered-inner', {
                      "text-dark-white": theme === "dark"
                    })}>
                      <b>
                        Copy Trading is a software application that enables individuals in the financial markets
                        to automatically copy positions opened and managed by another selected expert trader
                      </b>

                      <div className="row mt-3">
                        <div className="col-md-12 mb-3">
                          <div className="mb-3">
                            <label htmlFor="expert_code" ><b>Enter Expert Code</b></label>
                            <input className="form-control"
                              id="expert_code" onChange={(e) => setTraderInput(e.target.value)} type="text" placeholder="Enter Expert Copier Code" required />
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <div><button onClick={() => getTrader()} className="btn btn-primary" disabled={isFetchingTrader || !traderInput}>Search Expert</button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className={classNames("card border-gray-300 p-0  mb-4 ", {
            "bg-dark-card": theme === "dark"
          })} >
            <div className="card-body" style={{ marginBottom: '-10px' }}>
              <h3 className={classNames("h5 mb-3", {
                "text-dark-white": theme === "dark"
              })} style={{ 'borderBottom': 'solid 3px #000', 'padding': '0px 10px 10px 5px', 'display': 'inline-block' }}>My Copy Traders </h3>

            </div>
          </div>

          <div className="row" style={{ 'maxHeight': '480px', 'overflowY': 'auto' }}>
            {
              traders?.length > 0 &&
              traders.map((sub, k) => (
                <div className="col-md-6" key={k}>
                  <div className={classNames("card border-gray-300 p-0  mb-4 ", {
                    "bg-dark-card": theme === "dark"
                  })} style={{ 'cursor': 'pointer' }} onClick={() => navigate(`/user/copy-trader/${sub.trader.ref}`)} >
                    <div className={classNames("card-body", {
                                    "text-dark-white": theme === "dark"
                                })}>
                      <div><b><i className='fa fa-circle' style={{ 'fontSize': '11px', 'color': 'green' }}></i> &nbsp; Active</b></div>
                      <div>#{sub.trader.ref} <i className='fa fa-circle-arrow-right'></i></div>
                      <div className='mb-3'>{ucfirst(sub.trader.name)}</div>
                      <div>
                        <div className="row mb-2" >
                          <div className="col-4" style={{ 'fontSize': '13px' }}>
                            <span>Capital:</span>
                            <h5 className={classNames("h6 fw-bold mb-1", {
                              "text-dark-main": theme === "dark"
                            })}>{isAuthenticated ? currencyFormat(sub.amount) : <Skeleton width={150} />}</h5>
                          </div>
                          <div className="col-4" style={{ 'fontSize': '13px' }}>
                            <span>Profit:</span>
                            <h5 className={classNames("h6 fw-bold mb-1", {
                              "text-dark-main": theme === "dark"
                            })}>{isAuthenticated ? currencyFormat(sub.profit) : <Skeleton width={150} />}</h5>
                          </div>
                          <div className="col-4" style={{ 'fontSize': '13px' }}>
                            <span>Loss:</span>
                            <h5 className={classNames("h6 fw-bold mb-1", {
                              "text-dark-main": theme === "dark"
                            })}>{isAuthenticated ? currencyFormat(sub.loss) : <Skeleton width={150} />}</h5>
                          </div>
                        </div>
                        <span>Total Equity:</span>
                        <h5 className={classNames("h4 fw-bold mb-1", {
                              "text-dark-main": theme === "dark"
                            })}>{isAuthenticated ? currencyFormat((sub.amount + sub.profit) - sub.loss) : <Skeleton width={150} />}</h5>
                        <div className="small mt-2 text-success">
                          <span className="fas fa-angle-up "></span>
                          <span className=" fw-bold">({(((sub.profit - sub.loss) / sub.amount) * 100).toFixed(2)}%)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default CopyTrader