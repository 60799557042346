import React from 'react'
import { Outlet } from 'react-router-dom'
// import UseAuth from '../hooks/UseAuth'
// import Loader from '../components/Loader'

function HomeLayout() {

    // const { isAuthenticating } = UseAuth()

    return (
        <>
            {/* {isAuthenticating ? <Loader /> : */}
                <div>
                    {/* <Link to=''>Home</Link> |
                    <Link to='/auth/login'>Login</Link> |
                    <Link to='/auth/signup'>Register</Link> |
                    <Link to='/user/dashboard'>Account</Link>
                    <br /><br /> */}

                    <Outlet />
                </div>
            {/* } */}
        </>
    )
}

export default HomeLayout