import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from '../hooks/UseAuth';

function Test() {
  const { counter } = useAuth()
  return (
    <div>
      <b>Test</b><br />

<p>{counter}</p>
      <p><Link to='/'>Go to home</Link></p>
    </div>
  )
}

export default Test