import React, { useEffect, useMemo, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'

import * as api from '../../services/api_service'
import UseAuth from '../../hooks/UseAuth';
import AppHelpers from '../../hooks/AppHook';
import Transfers from '../../components/Transfers';
import classNames from 'classnames';

function Transfer() {

  const { user, setUser, isAuthenticated } = UseAuth()
  const { currencyFormat, ucfirst, theme } = AppHelpers()
  const [amount, setAmount] = useState(0)
  const [email, setEmail] = useState('')
  const amountRef = useRef()
  const emailRef = useRef()
  const [emailError, setEmailError] = useState(null)
  const [to, setTo] = useState(null)
  const [transfers, setTransfers] = useState([])
  const [isCheckingEmail, setIsCheckingEmail] = useState(false)
  const [isFetchingTransfers, setIsFetchingTransfers] = useState(true)
  const [isSending, setIsSending] = useState(false)

  const [show, setShow] = useState(false)

  useEffect(() => {
    async function getTransfers() {
      try {
        const resp = await api.transfers()
        setTransfers(resp.data.data)
        setIsFetchingTransfers(false)
      } catch (error) {
        console.log(error)
        setIsFetchingTransfers(false)
      }
    }

    getTransfers()
  }, [])

  const allTransfered = useMemo(() => {
    if (transfers.length > 0) {
      let t_ = 0
      transfers.forEach((e) => { t_ = parseFloat(e.amount) + t_ })
      return t_
    } else {
      return 0
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps     
  }, [transfers])

  const handleClose = () => {
    setShow(false)
    setTo(null)
    setAmount(0)
    setEmail('')
    amountRef.current.value = ''
    emailRef.current.value = ''
  }

  const handleShow = () => {
    setShow(true)
  }

  async function setCheckEmail(email_) {
    setEmail(email_)
    setIsCheckingEmail(true)
    setEmailError(null)
    setTo(null)

    try {
      const resp = await api.validateTransferEmail(email_)
      if (Object.keys(resp.data).length > 0) {
        setIsCheckingEmail(false)
        setTo(resp.data)
      } else {
        setEmailError("Invalid User Email")
        setIsCheckingEmail(false)
      }
    } catch (error) {
      console.log(error.response.data.message)
      setEmailError("Invalid User Email")
      setIsCheckingEmail(false)
    }
  }

  const toastConfig = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  async function handleTransfer() {
    setIsSending(true)

    try {
      const resp = await api.transfer({ email: to.email, amount: amount })
      setTransfers((t) => [...t, resp.data.data])
      setUser((user_) => ({ ...user_, balance: user_.balance - amount }))
      handleClose()
      setIsSending(false)
      toast.success("Transfer Sent Successfully", toastConfig)
    } catch (error) {
      console.log(error)
      setIsSending(false)
      toast.error(error.response.data.message, toastConfig)
    }
  }

  return (
    <div className="col-12 col-lg-8">
      <div className="row">

        <div className="col-md-6 mb-3">
          <div className={classNames("card", {
            "bg-dark-card text-dark-white": theme === "dark"
          })}>
            <div className="card-body">
              <div className={classNames('h5 mb-3', {
                "text-dark-white": theme === "dark"
              })}>Transfer Asset</div>
              <div style={{ fontSize: '14px' }} className='mb-4'>Transfer funds to any {process.env.REACT_APP_APP_NAME} user</div>

              <div className="mb-3">
                <label htmlFor="email" style={{ fontSize: '13px' }}>Receiver's Email address</label>
                <input type="email" className="form-control" id="email" ref={emailRef} onChange={(e) => setCheckEmail(e.target.value)} />
                <small id="emailHelp" className="form-text text-danger" style={{ fontSize: '13px', display: emailError && !isCheckingEmail && email !== '' && to === null ? 'block' : 'none' }}>{emailError}</small>
              </div>

              <div className="mb-3">
                <label htmlFor="amount" style={{ fontSize: '13px' }}>Amount</label>
                <input type="number" className="form-control" id="amount" ref={amountRef} placeholder='0.00' onChange={(e) => setAmount(e.target.value)} />
                <small id="amountHelp" className="form-text text-danger" style={{ fontSize: '13px', display: isAuthenticated && amount > user.balance ? 'block' : 'none' }}>Insufficient Funds.</small>
              </div>

              <button className='btn btn-tertiary mt-2' disabled={to === null || amount < 1 || amount > user.balance} onClick={() => handleShow()} >Submit</button>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 mb-4">
          <div className={classNames("card border-gray-300 mb-3", {
            "bg-dark-card text-dark-white": theme === "dark"
          })}>
            <div className="card-body d-block d-flex align-items-center">
              <div className={classNames("icon icon-shape icon-md icon-shape-primary rounded-circle me-3 mb-4 mb-md-0", {
                "text-dark-main": theme === "dark"
              })}><span className="fas fa-wallet"></span></div>
              <div><span className={classNames("d-block h6 fw-normal", {
                "text-dark-white": theme === "dark"
              })}>Wallet Balance</span>
                <h5 className={classNames("h3 fw-bold mb-1", {
                  "text-dark-white": theme === "dark"
                })}>{isAuthenticated ? currencyFormat(user.balance) : <Skeleton width={150} />}</h5>
                <div className="small mt-2"><small>Investments are added after it elapses</small></div>
              </div>
            </div>
          </div>

          <div className={classNames("card border-gray-300", {
            "bg-dark-card text-dark-white": theme === "dark"
          })}>
            <div className="card-body d-block d-md-flex align-items-center">
              <div className={classNames("icon icon-shape icon-md icon-shape-primary rounded-circle me-3 mb-4 mb-md-0", {
                "text-dark-main": theme === "dark"
              })}><span className="fas fa-file-invoice-dollar"></span></div>
              <div><span className={classNames("d-block h6 fw-normal", {
                "text-dark-white": theme === "dark"
              })}>Total Transfer</span>
                <h5 className={classNames("h3 fw-bold mb-1", {
                  "text-dark-white": theme === "dark"
                })}>{isAuthenticated ? currencyFormat(allTransfered) : <Skeleton width={150} />}</h5>
                <div className="small mt-2"><small>Total Asset Transfered</small></div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="row mt-3">
        <div className="col-12 mb-4">
          <Transfers transfers={transfers} isFetchingTransfers={isFetchingTransfers} paginate={6} />
        </div>
      </div>

      {
        to !== null && <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className='mt-n7'
        >
          <Modal.Body className='pt-4'>
            <div className='col-12' style={{ 'padding': '5px 10px' }}>
              <div className='text-left'>
                <div style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', fontSize: '13px' }} className='bg-primary text-white'>Transfer Overview</div><br />
                {/* <div style={{ fontSize: '20px', marginLeft: 4, marginBottom: '10px' }}>{activePackage.name && (`${activePackage.name}`).toUpperCase()}</div> */}

                <div className='mb-3'>

                  <div className='mt-3'>
                    <p>You Are About To Transfer <b>{currencyFormat(amount)}</b> to <b>{ucfirst(to.firstname)} {ucfirst(to.lastname)}</b></p>
                    <div>
                      <div className='mb-2'>Summary <hr /></div>
                      <div style={{ fontSize: '14px' }} className='mb-2'>
                        <span><b>From:</b></span><br />
                        <span>Wallet: <b>{ucfirst(currencyFormat(user.balance))}</b></span><br />
                      </div>
                      <div style={{ fontSize: '14px' }} className='mb-4'>
                        <span><b>To:</b></span><br />
                        <span>Fullname: <b>{ucfirst(to.firstname)} {ucfirst(to.lastname)}</b></span><br />
                        <span>Email: <b>{to.email}</b></span><br />
                        <span>Amount: <b>{currencyFormat(amount)}</b></span><br />
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>

                <div className="mb-3 row">

                  <div className='col-4' style={{ 'textAlign': 'center', padding: '10px' }}>
                    <i className='fa fa-wallet' style={{ 'fontSize': '38px' }} />
                    <div className='mt-2 text-danger'><i className='fa fa-minus' style={{ fontSize: '10px' }} /> {currencyFormat(amount)}</div>
                  </div>

                  <div className='col-4' style={{ 'textAlign': 'center', padding: '10px', position: 'relative', top: '10px', 'fontSize': '30px' }}><i className='fa fa-arrow-right' /></div>

                  <div className='col-4' style={{ 'textAlign': 'center', padding: '10px' }}>
                    <i className='fa fa-user'></i><br />
                    <span>{ucfirst(to.firstname)} {ucfirst(to.lastname)}</span>
                  </div>

                </div>

                <div>
                  <button className='btn btn-xs btn-danger mt-2' onClick={handleClose}>Discard</button> &nbsp; &nbsp;
                  <button className='btn btn-sm btn-primary mt-2' disabled={to === null || amount < 1 || amount > user.balance || isSending} onClick={() => handleTransfer()}>Confirm Transfer</button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }

    </div>
  )
}

export default Transfer