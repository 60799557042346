import React, { useEffect, useMemo, useState } from 'react'
// import { Link } from 'react-router-dom'
import UseAuth from '../../hooks/UseAuth';
import AppHelpers from '../../hooks/AppHook';
import Skeleton from 'react-loading-skeleton'
import * as api from '../../services/api_service'

import StackingChart from '../../components/StackingChart';
import ProfitChart from '../../components/ProfitChart';
import Invests from '../../components/Invests';
import classNames from 'classnames';

function Dashboard() {

    const { user, isAuthenticated } = UseAuth()
    const { currencyFormat, theme } = AppHelpers()
    const [investments, setInvestments] = useState([])
    const [traders, setTraders] = useState([])
    const [trades, setTrades] = useState([])
    const [isFetching, setisFetching] = useState(true)
    useEffect(() => {
        async function getInvestments() {
            try {
                const resp = await Promise.all([
                    api.investments(),
                    api.trades(),
                    api.subscriptions(),
                    // api.test(),
                ]);
                console.log('all', resp)
                // console.log(resp[3].data)
                setisFetching(false)
                setInvestments(resp[0].data.data)
                setTrades(resp[1].data.data)
                setTraders(resp[2].data.data)
            } catch (error) {
                setisFetching(false)
                console.log(error)
            }
        }

        getInvestments()
    }, [])

    const activeInvestments = useMemo(() => {
        return investments.length > 0 ? investments.filter((e) => e.status !== 2) : []
    }, [investments])

    const profit = useMemo(() => {
        let profit_ = 0
        activeInvestments.length > 0 && activeInvestments.map((e) => e.profits.map((profit) => profit_ += parseFloat(profit.amount)))
        return profit_
    }, [activeInvestments])

    const activeInvestmentsBalance = useMemo(() => {
        let invBalance_ = 0
        activeInvestments.length > 0 && activeInvestments.map((e) => invBalance_ += parseFloat(e.capital))
        return invBalance_
    }, [activeInvestments])

    const activeTraders = useMemo(() => {
        return traders.length > 0 ? traders.filter((e) => e.status !== 1) : []
    }, [traders])

    const activeTradersProfit = useMemo(() => {
        let profit_ = 0
        activeTraders.length > 0 && activeTraders.forEach((e) => profit_ += parseFloat(e.profit))
        return profit_
    }, [activeTraders])

    const activeTradersBalance = useMemo(() => {
        let subBalance_ = 0
        activeTraders.length > 0 && activeTraders.map((e) => subBalance_ += parseFloat(e.amount))
        return subBalance_
    }, [activeTraders])

    const activeTrades = useMemo(() => {
        return trades.length > 0 ? trades.filter((e) => e.status === 1) : []
    }, [traders])

    const activeTradesBalance = useMemo(() => {
        let tradeBalance_ = 0
        activeTrades.length > 0 && activeTrades.map((e) => tradeBalance_ += parseFloat(e.amount))
        return tradeBalance_
    }, [activeTrades])

    return (
        <div className={classNames("col-12 col-lg-8", {
            "text-dark-white": theme === "dark"
        })}>
            <div className="row">
                <div className="col-12 col-sm-6 mb-4">
                    <div className={classNames("card border-gray-300", {
                        "bg-dark-card text-dark-white": theme === "dark"
                    })}>
                        <div className="card-body d-block d-flex align-items-center">
                            <div className={classNames("icon icon-shape icon-md icon-shape-primary rounded-circle me-3 mb-4 mb-md-0", {
                                "text-dark-main": theme === "dark"
                            })}><span className="fas fa-wallet"></span></div>
                            <div><span className={classNames("d-block h6 fw-normal", {
                                "text-dark-white": theme === "dark"
                            })}>Wallet Balance</span>
                                <h5 className={classNames("h3 fw-bold mb-1", {
                                    "text-dark-white": theme === "dark"
                                })}>{isAuthenticated ? currencyFormat(user.balance) : <Skeleton width={150} />}</h5>
                                <div className="small mt-2"><small>Investments are added after it elapses</small></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 mb-4">
                    <div className={classNames("card border-gray-300", {
                        "bg-dark-card": theme === "dark"
                    })}>
                        <div className="card-body d-block d-flex align-items-center">
                            <div className={classNames("icon icon-shape icon-md icon-shape-primary rounded-circle me-3 mb-4 mb-md-0", {
                                "text-dark-main": theme === "dark"
                            })}><span className="fas fa-file-invoice-dollar"></span></div>
                            <div><span className={classNames("d-block h6 fw-normal", {
                                "text-dark-white": theme === "dark"
                            })}>Active Investments</span>
                                <h5 className={classNames("h3 fw-bold mb-1", {
                                    "text-dark-white": theme === "dark"
                                })}>{isAuthenticated ? currencyFormat(activeInvestmentsBalance) : <Skeleton width={150} />}</h5>
                                <div className="small mt-2"><span className="fas fa-angle-up text-success"></span> <span className="text-success fw-bold">{isAuthenticated ? currencyFormat(profit) : <Skeleton width={150} />}</span> <small>(
                                    {/* {<span className="text-success fw-bold">4.2%</span>}  */}
                                    Increase in profit)</small></div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12 col-sm-6 mb-4">
                    <div className={classNames("card border-gray-300", {
                        "bg-dark-card": theme === "dark"
                    })}>
                        <div className="card-body d-block d-flex align-items-center">
                            <div className={classNames("icon icon-shape icon-md icon-shape-primary rounded-circle me-3 mb-4 mb-md-0", {
                                "text-dark-main": theme === "dark"
                            })}><span className="fas fa-copy"></span></div>
                            <div><span className={classNames("d-block h6 fw-normal", {
                                "text-dark-white": theme === "dark"
                            })}>Active Trade Copier</span>
                                <h5 className={classNames("h3 fw-bold mb-1", {
                                    "text-dark-white": theme === "dark"
                                })}>{isAuthenticated ? currencyFormat(activeTradersBalance + activeTradersProfit) : <Skeleton width={150} />}</h5>
                                <div className="small mt-2"><span className="fas fa-angle-up text-success"></span> <span className="text-success fw-bold">{isAuthenticated ? currencyFormat(activeTradersProfit) : <Skeleton width={150} />}</span> <small>(
                                    {/* {<span className="text-success fw-bold">4.2%</span>}  */}
                                    Increase in profit)</small></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 mb-4">
                    <div className={classNames("card border-gray-300", {
                        "bg-dark-card": theme === "dark"
                    })}>
                        <div className="card-body d-block d-flex align-items-center">
                            <div className={classNames("icon icon-shape icon-md icon-shape-primary rounded-circle me-3 mb-4 mb-md-0", {
                                "text-dark-main": theme === "dark"
                            })}><span className="fas fa-area-chart"></span></div>
                            <div><span className={classNames("d-block h6 fw-normal", {
                                "text-dark-white": theme === "dark"
                            })}>Active Trades</span>
                                <h5 className={classNames("h3 fw-bold mb-1", {
                                    "text-dark-white": theme === "dark"
                                })}>{isAuthenticated ? currencyFormat(activeTradesBalance) : <Skeleton width={150} />}</h5>
                                <div className="small mt-2"><small>Opened trades</small></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 mb-4">
                    <div className={classNames("card border-gray-300", {
                        "bg-dark-card": theme === "dark"
                    })}>
                        <div className="card-body d-flex flex-row align-items-center flex-0 border-bottom">
                            <div className="d-block">
                                <div className={classNames("h6 fw-normal text-gray mb-2", {
                                    "text-dark-white": theme === "dark"
                                })}>Portfolio Activity</div>
                                <div className="d-flex align-items-center text-left mb-2"><span className="shape-xs rounded-circle bg-tertiary me-2"></span>
                                    <span className="fw-normal small">
                                        Balance</span>
                                </div>
                            </div>
                            <div className="d-block ms-auto">
                                <div className="d-flex align-items-center text-right mb-2"><span className="shape-xs rounded-circle bg-dark me-2"></span>
                                    <span className="fw-normal small">
                                        Investment</span>
                                </div>
                                <div className="d-flex align-items-center text-right"><span className="shape-xs rounded-circle bg-success me-2"></span>
                                    <span className="fw-normal small">
                                        Profit
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ padding: '10px 50px', 'textAlign': 'center' }}>
                            <StackingChart investments={activeInvestments} profit={profit} />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 mb-4">
                    <div className={classNames("card border-gray-300", {
                        "bg-dark-card": theme === "dark"
                    })}>
                        <div className="card-body d-flex flex-row align-items-center flex-0 border-bottom">
                            <div className="d-block">
                                <div className={classNames("h6 fw-normal text-gray mb-2", {
                                    "text-dark-white": theme === "dark"
                                })}>Earnings Activity</div>
                            </div>
                            <div className="d-block ms-auto">
                                <div className="d-flex align-items-center text-right mb-2"><span className="shape-xs rounded-circle bg-tertiary me-2"></span> <span className="fw-normal small">Earning</span></div>
                            </div>
                        </div>
                        <div className="card-body" style={{ paddingBottom: '27px', 'textAlign': 'center' }}>
                            <ProfitChart investments={investments} />
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <div className={classNames("card", {
                        "bg-dark-card": theme === "dark"
                    })}>
                        <div className="card-body">
                            <iframe src={`${process.env.REACT_APP_HOME_URL}/charts/trade/full`} frameBorder="0" width={'100%'} height={'300px'} title='chart'></iframe>
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <Invests investments={investments} isFetching={isFetching} paginate={4} />
                </div>
            </div>
        </div>
    )
}

export default Dashboard