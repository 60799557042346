import React, { useEffect, useMemo, useState } from 'react'
import AppHelpers from '../../hooks/AppHook';
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify';
import * as api from '../../services/api_service'
import UseAuth from '../../hooks/UseAuth';
import profilePic from '../../assets/image/profile/default.png'
import traderPic from '../../assets/image/illus/trader.png'
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

function Trader() {

  const { user, setUser, isAuthenticated } = UseAuth()
  const { id: traderId } = useParams();
  const navigate = useNavigate();
  const { ucfirst, currencyFormat } = AppHelpers()
  const [trades, setTrades] = useState([])
  const [trader, setTrader] = useState()
  const [isFetchingTrades, setIsFetchingTrades] = useState(true)
  const [show, setShow] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [isPostingSubscribe, setIsPostingSubscribe] = useState(false)
  const [amount, setAmount] = useState({})
  // const copyText = useRef()

  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await Promise.all([
          api.trader(traderId),
          // api.downlineEarnings()
        ])
        console.log(resp)
        setTrades(resp[0].data.data.trades)
        setTrader(resp[0].data.data)
        setIsFetchingTrades(false)
      } catch (error) {
        navigate('/user/copy-trader')
        console.log(error)
        setIsFetchingTrades(false)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  }, [])

  async function handleSubscribe() {
    setIsPostingSubscribe(true)
    try {
      const resp = await api.traderSubscribe({ trader_id: trader?.id, amount: amount, })
      setUser((user_) => {
        return { ...user_, balance: user_.balance - parseFloat(amount) }
      })
      setTrader(resp.data.data)
      setAmount('')
      toast.success('Subscription request sent', toastConfig)
      handleClose()
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, { ...toastConfig, autoClose: 8000 })
      setIsPostingSubscribe(false)
    }
  }

  async function handleUnsubscribe() {

    setIsPostingSubscribe(true)
    try {
      const resp = await api.traderUnsubscribe({ trader_id: trader?.id })
      setTrader(resp.data.data)
      toast.success('Request sent. Your request will be confirmed shortly', toastConfig)
      handleCloseConfirm()
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, { ...toastConfig, autoClose: 8000 })
      setIsPostingSubscribe(false)
    }
  }

  const handleClose = () => {
    setShow(false)
    setAmount("")
  }

  const handleShow = () => {
    if (trader.min_investment > user.balance) return toast.error('Insufficient balance', toastConfig)
    setAmount("")
    setShow(true)
  }

  const handleCloseConfirm = () => {
    setShowConfirm(false)
    // setAmount("")
  }

  const handleShowConfirm = () => {
    // if (trader.min_investment > user.balance) return toast.error('Insufficient balance', toastConfig)
    // setAmount("")
    setShowConfirm(true)
  }

  const isSubscribed = useMemo(() => {
    if (trader && trader?.subscribers?.length > 0) {
      let sub_ = trader.subscribers.filter((e => e.user_id === user.id))
      return sub_.length > 0 ? sub_[sub_.length - 1].status : null
    }
    return null
  }, [trader, user]);

  const myEquity = useMemo(() => {
    if (trader && trader.subscribers.length > 0) {
      let sub_ = trader.subscribers.filter((e => e.user_id === user.id))
      return sub_.length > 0 ? sub_[sub_.length - 1].amount+(sub_[sub_.length - 1].profit-sub_[sub_.length - 1].loss) : 0
    }
    return 0
  }, [trader, user]);

  const myProfit = useMemo(() => {
    if (trader && trader.subscribers.length > 0) {
      let sub_ = trader.subscribers.filter((e => e.user_id === user.id))
      return sub_.length > 0 ? sub_[sub_.length - 1].profit-sub_[sub_.length - 1].loss : 0
    }
    return 0
  }, [trader, user]);

  const toastConfig = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  // function handleCopy() {
  //   console.log(copyText.current.value)
  //   copyText.current?.select();
  //   document.execCommand("copy");
  //   copyText.current.focus()
  //   toast.success("Copied to clipboard", toastConfig)
  // }

  return (
    <div className="col-12 col-lg-8">

      <div className='row'>
        <div className='col-md-6 mb-3'>
          <div className="card">
            <div className="card-body">
              <div className="d-block h6 fw-normal">
                <div className="d-flex" style={{'justifyContent': 'space-between'}}>
                  <h3 className="h5 mb-3" style={{ 'borderBottom': 'solid 3px #000', 'padding': '0px 10px 10px 5px', 'display': 'inline-block' }}>Copy Trader </h3>
                  <div>
                    <button className='btn btn-link' style={{'fontSize': '17px'}} onClick={() => navigate('/user/copy-trader')}><i className='fa fa-circle-arrow-left'></i> Back</button>
                  </div>
                </div>

                <center>
                  {isAuthenticated && !isFetchingTrades ?
                    <>
                      <div className="profile-thumbnail dashboard-avatar mx-lg-auto me-3 mb-2"><div style={{ backgroundImage: isAuthenticated && trader ? `url(${trader.image})` : `url(${profilePic})` }} className="card-img-top profile-image-div rounded-circle border-white" alt="Image" /></div>
                      <h5>#{trader.ref}</h5>
                      <h6>{ucfirst(trader.name)} <i className='fa fa-check-circle' style={{ 'color': 'green' }}></i></h6>
                      <span>Joined: {trader.joined}</span>
                      <div className='mt-2'><b><i className='fa fa-circle' style={{ 'fontSize': '11px', 'color': 'green' }}></i> &nbsp; Active</b></div>
                      {
                        isSubscribed === 2 ?
                          <button className='btn btn-primary btn-md mt-4' onClick={handleShowConfirm}>Subscribed <i className='fa fa-check'></i></button> :
                          isSubscribed === 1 ?
                            <button className='btn btn-primary btn-md mt-4' disabled={true}>Pending Subscription <i className='fa fa-spinner fa-spin'></i></button> :
                            isSubscribed === 0 ?
                            <button className='btn btn-primary btn-md mt-4' disabled={true}>Unsubscribe Pending <i className='fa fa-spinner fa-spin'></i></button> :
                            <button className='btn btn-primary btn-md mt-4' onClick={() => handleShow()}>Subscribe To Trade</button>
                      }
                    </> :
                    <>
                      <div className="profile-thumbnail dashboard-avatar mx-lg-auto me-3 mb-2"><div style={{ backgroundImage: `url(${profilePic})` }} className="card-img-top profile-image-div rounded-circle border-white" alt="Image" /></div>
                      <Skeleton width={100} /><br />
                      <Skeleton width={100} /><br />
                      <Skeleton width={100} /><br />
                      <Skeleton width={100} /><br />
                    </>
                  }
                </center><br />

              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className='card mb-3' >
            <div className="card-body d-block " style={{ 'paddingBottom': '220px', 'backgroundImage': `url(${traderPic})`, 'backgroundPosition': '100px 150px ', 'backgroundSize': '400px', 'backgroundRepeat': 'no-repeat' }}>

              <div>
                <div className='row mb-4'>
                  <div className='col-6'>
                    <span>Total Equity:</span>
                    <h5 className="h5 fw-bold mb-1">{isAuthenticated && !isFetchingTrades ? currencyFormat(trader.total_equity) : <Skeleton width={100} />}</h5>
                    <div className="small mt-2 text-success">
                      <span className="fas fa-angle-up "></span>
                      <span className=" fw-bold">(12%)</span>
                    </div>
                  </div>
                  <div className='col-6'>
                    <span>My Equity:</span>
                    <h5 className="h5 fw-bold mb-1">{isAuthenticated && !isFetchingTrades ? currencyFormat(myEquity) : <Skeleton width={100} />}</h5>
                    <div className="small mt-2 text-success">
                      <span className="fas fa-angle-up "></span>
                      <span className=" fw-bold">{isAuthenticated && !isFetchingTrades && currencyFormat(myProfit)}</span>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-5" style={{ 'fontSize': '13px' }}>
                    <span>Profit:</span>
                    <h5 className="h5 fw-bold mb-1">{isAuthenticated && !isFetchingTrades ? currencyFormat(trader.total_profit) : <Skeleton width={50} />}</h5>
                  </div>
                  <div className="col-4" style={{ 'fontSize': '13px' }}>
                    <span>Loss:</span>
                    <h5 className="h5 fw-bold mb-1">{isAuthenticated && !isFetchingTrades ? currencyFormat(trader.total_loss) : <Skeleton width={50} />}</h5>
                  </div>
                  <div className="col-3" style={{ 'fontSize': '13px' }}>
                    <span>Risk:</span>
                    <h5 className="h5 fw-bold mb-1">{isAuthenticated && !isFetchingTrades ? ucfirst(trader.risk) : <Skeleton width={50} />}</h5>
                  </div>
                </div>
                <div className="row mb-4" >
                  <div className="col-4" style={{ 'fontSize': '14px' }}>
                    <span>Min. Equity:</span>
                    <h5 className="h5 fw-bold mb-1">{isAuthenticated && !isFetchingTrades ? currencyFormat(trader.min_investment) : <Skeleton width={50} />}</h5>
                  </div>
                  <div className="col-4" style={{ 'fontSize': '14px' }}>
                    <span>Copiers:</span>
                    <h5 className="h5 fw-bold mb-1">{isAuthenticated && !isFetchingTrades ? trader.total_copiers : <Skeleton width={50} />}</h5>
                  </div>
                  <div className="col-4" style={{ 'fontSize': '14px' }}>
                    <span>Commission:</span>
                    <h5 className="h5 fw-bold mb-1">{isAuthenticated && !isFetchingTrades ? trader.commission + '%' : <Skeleton width={50} />}</h5>
                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>

      </div>

      <div className="row mb-3" >
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div>
                <div className='mt-2'>
                  <div className="mb-2">
                    <b>
                      <i className='fa fa-star'></i> Expert
                    </b>
                  </div>
                  <div className='mb-2'>
                    <b>Description :</b>
                  </div>
                  <b>{trader?.description}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card border-gray-300">

            <div className="card-body">
              <div className="d-block">
                <div className="h5 fw-normal text-gray mt-2">Recent Trades</div>
              </div>

              {
                trades.length > 0 ?
                  <div style={{'width': '100%', 'maxHeight': '400px', 'overflow': 'auto'}}>
                    <div className="table-responsive">
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>Symbol</th>
                            <th>Order Type</th>
                            <th>T/P %</th>
                            <th>Entry</th>
                            <th>Direction</th>
                            <th><i className='fa fa-clock'></i></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            trades.sort((a, b) => (b.id - a.id)).map((e, k) => (
                              <tr key={k}>
                                <td style={{ 'backgroundColor': e.status === 2 ? 'green' : e.status === 1 ? 'orange' : 'red', 'color': '#fff' }}>{e.symbol}</td>
                                <td>{ucfirst(e.order_type)}</td>
                                <td>{e.tp}</td>
                                <td>{e.entry || '----'}</td>
                                <td>{e.direction}</td>
                                <td>{e.created_at}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>

                  </div> : isFetchingTrades ? <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Symbol</th>
                          <th>Order Type</th>
                          <th>T/P %</th>
                          <th>Entry</th>
                          <th>Direction</th>
                          <th><i className='fa fa-clock'></i></th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.from(Array(4)).map((e, k) => (
                          <tr key={k}>
                            <td><Skeleton /></td><td><Skeleton /></td>
                            <td><Skeleton /></td><td><Skeleton /></td>
                            <td><Skeleton /></td><td><Skeleton /></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div> :
                    <div>
                      <p>No result found</p>
                    </div>
              }
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
        className='mt-n7'
      >
        <Modal.Body className='pt-4'>
          <div className='col-12' style={{ 'padding': '5px 10px' }}>
            <div className='text-left'>
              {/* <div style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', fontSize: '13px' }} className='bg-primary text-white'>Selected Package</div><br />
              <div style={{ fontSize: '20px', marginLeft: 4, marginBottom: '10px' }}>{activePackage.name && (`${activePackage.name}`).toUpperCase()}</div> */}

              <div>
                <div className='mb-2'>
                  <span>Enter Amount To Trade (Invest)</span>
                </div>

                <div style={{ display: 'flex', fontSize: '14px' }} className='mb-3'>
                  <div style={{ display: 'inline-block' }}><b>Note :</b></div> &nbsp;
                  <div style={{ display: 'inline-block' }}>
                    <span>Minimum Equity : {trader?.min_investment && currencyFormat(trader?.min_investment)}</span><br />
                  </div>
                </div>

                <div style={{ display: 'flex', fontSize: '13px' }}>
                  <div style={{ display: 'inline-block', marginRight: '10px' }}><b>Amount ($):</b></div>
                  <div style={{ display: 'inline-block' }}>
                    <input type="number" className='form-control' placeholder='0.00' onChange={(e) => setAmount(e.target.value)} readOnly={isPostingSubscribe} />
                    <div className='text-danger' style={{ display: amount > 0 && amount < trader?.min_investment ? 'block' : 'none' }}>Amount is lesser than required minimum equity by this trader</div>
                    <div className='text-danger' style={{ display: amount > 0 && amount > user?.balance ? 'block' : 'none' }}>Insufficient Funds</div>
                    <button onClick={handleSubscribe} className='btn btn-primary mt-4' disabled={amount <= 0 || amount < trader?.min_investment || amount > user?.balance || isPostingSubscribe}><span style={{ padding: '0px 10px' }}>Submit</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showConfirm}
        onHide={handleCloseConfirm}
        // backdrop="static"
        keyboard={false}
        centered
        className='mt-n7'
      >
        <Modal.Body className='pt-4'>
          <div className='col-12' style={{ 'padding': '5px 10px' }}>
            <div className='text-left'>
              {/* <div style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', fontSize: '13px' }} className='bg-primary text-white'>Selected Package</div><br />
              <div style={{ fontSize: '20px', marginLeft: 4, marginBottom: '10px' }}>{activePackage.name && (`${activePackage.name}`).toUpperCase()}</div> */}

              <div>
                <div className='mb-2'>
                  <div className='mb-1'>Unsubscribe</div>
                  <div>Are you sure ?</div>
                </div>

                <button onClick={handleUnsubscribe} className='btn btn-danger mt-4' ><span style={{ padding: '0px 10px' }}>Confirm</span></button>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>



    </div>
  )
}

export default Trader