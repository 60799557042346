import React, { useEffect, useMemo, useState } from 'react'
import AppHelpers from '../hooks/AppHook';
import Skeleton from 'react-loading-skeleton'
import UseAuth from '../hooks/UseAuth';
import classNames from 'classnames';

function Transfers({ transfers: allTransfers, paginate = 50, isFetchingTransfers }) {

    const { currencyFormat, ucfirst, theme } = AppHelpers()
    const { user } = UseAuth()
    const [filteredTransfers, setFilteredTransfers] = useState([])
    const [paginatedTransfers, setPaginatedTransfers] = useState([])
    const [sort, setSort] = useState('all')
    const [activePage, setActivePage] = useState(0)

    const transfers = useMemo(() => {
        return allTransfers.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }, [allTransfers])

    useEffect(() => {
        setFilteredTransfers(transfers)
        setPaginatedTransfers(transfers.slice(0, paginate))
    }, [transfers, paginate])

    useEffect(() => {
        setPaginatedTransfers(filteredTransfers.slice(0, paginate))
    }, [filteredTransfers, paginate])

    const allPages = useMemo(() => {
        return Math.ceil(filteredTransfers.length / paginate)
    }, [filteredTransfers, paginate])

    function handleSort(data) {
        if (data === 'month') {
            let now = new Date()

            let filter_ = transfers.filter((e) => {
                let newDate = new Date(e.created_at)
                return newDate.getMonth() === now.getMonth() && newDate.getFullYear() === now.getFullYear()
            })

            setFilteredTransfers(filter_)
        } else {
            setFilteredTransfers(transfers)
        }
        setSort(data)
        setActivePage(0)
    }

    function handlePaginate(data) {
        let newPageStart = data * paginate
        setPaginatedTransfers(filteredTransfers.slice(newPageStart, newPageStart + paginate))
        setActivePage(data)
    }


    return (
        <div className={classNames("card border-gray-300", {
            "bg-dark-card text-dark-white": theme === "dark"
        })}>
            <div className="card-body d-flex flex-row align-items-center flex-0 border-bottom">
                <div className="d-block">
                    <div className={classNames("h5 fw-normal text-gray mt-2", {
                        "text-dark-white": theme === "dark"
                    })}>Recent Transfers</div>
                </div>
                <div className="d-flex ms-auto">
                    <button className={sort === 'month' ? 'btn btn-sm me-3 btn-tertiary' : 'btn border-gray-300 btn-sm me-3 btn-white'} onClick={() => handleSort('month')}>Month</button>
                    <button className={sort === 'all' ? 'btn btn-sm me-3 btn-tertiary' : 'btn border-gray-300 btn-sm me-3 btn-white'} onClick={() => handleSort('all')}>See All</button>
                </div>
            </div>
            <div className="card-body">
                {/* <SkeletonTheme baseColor="#eee" highlightColor="#999">
                                <p>
                                    <Skeleton count={3} />
                                </p>
                            </SkeletonTheme> */}
                {
                    paginatedTransfers.length > 0 ?
                        <div style={{ width: '100%', height: 'auto' }}>
                            <div className="table-responsive">
                                <table className='table'>
                                    <thead>
                                        <tr className={classNames({
                                            "text-dark-white": theme === "dark"
                                        })}>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            paginatedTransfers.map((e, k) => (
                                                <tr key={k}>
                                                    <td>{e.created_at}</td>
                                                    <td className={e.to.id === user.id ? 'text-success' : 'text-danger'}>{e.to.id === user.id ? '+' : '-'}{currencyFormat(e.amount)}</td>
                                                    <td>{ucfirst(e.from.firstname)} {ucfirst(e.from.lastname)}</td>
                                                    <td>{ucfirst(e.to.firstname)} {ucfirst(e.to.lastname)}</td>
                                                    <td>{e.to.id === user.id ? 'Credit' : 'Debit'}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='text-right p-2'>
                                {
                                    allPages > 0 && filteredTransfers.length > paginate && Array.from(Array(allPages)).map((e, k) => (
                                        <button key={k} className={activePage === k ? 'btn btn-sm me-3 btn-tertiary' : 'btn border-gray-300 btn-sm me-3 btn-white'} onClick={() => handlePaginate(k)}>{k + 1}</button>
                                    ))
                                }
                            </div>
                        </div> : isFetchingTransfers ? <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr className={classNames({
                                        "text-dark-white": theme === "dark"
                                    })}>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(Array(8)).map((e, k) => (
                                        <tr key={k}>
                                            <td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td><td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td><td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td>
                                            <td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td><td><Skeleton baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> :
                            <div>
                                <p>No result found</p>
                            </div>
                }
            </div>
        </div>


    )
}

export default Transfers