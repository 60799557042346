
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Modal from 'react-bootstrap/Modal'
import AppHelpers from '../hooks/AppHook'
import UseAuth from '../hooks/UseAuth'
import { toast } from 'react-toastify';
import * as api from '../services/api_service'

function Balance() {

    const { currencyFormat } = AppHelpers()
    const { user, isAuthenticated, setUser } = UseAuth()
    const [show, setShow] = useState(false)
    const [isSwitching, setIsSwitching] = useState(false)
    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    const balanceToggle = async () => {
        setIsSwitching(true)
        try {
            const resp = await api.walletToggle();
            setUser((_user) => ({ ..._user, demo: !user?.demo }))
            toast.success(resp.data.message, { theme: "dark" })
            setIsSwitching(false)
        } catch (error) {
            console.log(error)
            setIsSwitching(false)
        }
    }

    return (
        <>
            <button onClick={handleShow} className="btn btn-secondary">
                <span className='fas fa-wallet' style={{ marginRight: '8px' }}></span>
                {isAuthenticated ? user?.demo ? `Demo: ${currencyFormat(user.demo_balance)}` : currencyFormat(user.balance) : <Skeleton width={50} />}
                &nbsp; <i className='fa fa-caret-down' />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
                centered
                className='mt-n6'
            >
                <Modal.Body className='pt-4'>
                    <div className='col-12' style={{ 'padding': '5px 10px' }}>
                        <div className='text-left'>
                            <div style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', fontSize: '13px' }} className='bg-primary text-white'>My wallet</div><br />
                            {/* <div style={{ fontSize: '20px', marginLeft: 4, marginBottom: '10px' }}>{activePackage.name && (`${activePackage.name}`).toUpperCase()}</div> */}

                            <div className='row mt-3 mb-3'>
                                <div className="col-6">
                                    <div className='wallet-card'>
                                        <div className='wallet-card-title'>Live Balance</div>
                                        <div className='wallet-card-bal'>{currencyFormat(user.balance)}</div>
                                        <div className='mt-2'>
                                            {
                                                user.demo ?
                                                    <div className='wallet-switch bg-primary text-white' onClick={() => !isSwitching && balanceToggle()}>
                                                        {isSwitching ? <i className='fa fa-spinner fa-spin' /> : <span>Switch to live</span>}
                                                    </div> :
                                                    <div className='text-primary wallet-active'>
                                                        <i className='fa fa-circle' style={{ 'fontSize': '11px' }} /> Active
                                                    </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='wallet-card'>
                                        <div className='wallet-card-title'>Demo Balance</div>
                                        <div className='wallet-card-bal'>{currencyFormat(user.demo_balance)}</div>
                                        <div className='mt-2'>
                                            {
                                                !user.demo ?
                                                    <div className='wallet-switch bg-primary text-white' onClick={() => !isSwitching && balanceToggle()}>
                                                        {isSwitching ? <i className='fa fa-spinner fa-spin' /> : <span>Switch to demo</span>}
                                                    </div> :
                                                    <div className='text-primary wallet-active'>
                                                        <i className='fa fa-circle' style={{ 'fontSize': '10px' }} /> Active
                                                    </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className='row mt-3 mb-3'>
                                <div className="col-6">
                                    <div className='wallet-card'>
                                        <div className='wallet-card-title'>Referral Balance</div>
                                        <div className='wallet-card-bal'>{currencyFormat(user.referral_balance)}</div>
                                    </div>
                                </div>

                                {/* <div className="col-6">
                                    <div className='wallet-card'>
                                        <div className='wallet-card-title'>Demo Balance</div>
                                        <div className='wallet-card-bal'>{currencyFormat(user.demo_balance)}</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Balance