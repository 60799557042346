import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import * as api from '../../services/api_service'
import AppHelpers from '../../hooks/AppHook';
import { useNavigate } from 'react-router-dom';

function TokenWithdraw() {

  const navigate = useNavigate()
  const { currencyFormat } = AppHelpers()
  const [withdrawals, setWithdrawals] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [isFetchingWithdrawals, setIsFetchingWithdrawals] = useState(true)
  const [token, setToken] = useState('')
  const [network, setNetwork] = useState('')
  const [address, setAddress] = useState('')
  const [transactionID, setTransactionID] = useState('')

  useEffect(() => {
    async function getWithdrawals() {
      try {
        const resp = await api.withdrawals()
        let with_ = resp.data.data.filter((e) => e.status === 1);
        if (with_.length === 0) {
          return navigate(`/user/transaction/withdraw`)
        }
        setWithdrawals(with_)
        setIsFetchingWithdrawals(false)
      } catch (error) {
        console.log(error)
        setIsFetchingWithdrawals(false)
      }
    }

    getWithdrawals()
  }, [])

  async function withdraw() {
    setIsFetching(true)
    try {
      const resp = await api.tokenWithdraw({
        token: token,
        network: network,
        address: address,
        transaction_id: transactionID,
      })
      toast.success(resp.data.message, toastConfig)
      return navigate(`/user/transaction/withdraw`)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, { ...toastConfig, autoClose: 8000 })
      setIsFetching(false)
    }
  }

  const toastConfig = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  return (
    <div className="col-12 col-lg-8">

      <div className="row">
        <div className="col-lg-12">
          <div className="card border-gray-300 p-0 p-md-4 mb-4 " >
            <div className="card-body">
              <h3 className="h5 mb-3" style={{ 'borderBottom': 'solid 3px #000', 'padding': '0px 10px 10px 5px', 'display': 'inline-block' }}>Authenticate Withdrawal</h3>

              {isFetchingWithdrawals ?
                <div className='p-5' style={{ textAlign: 'center' }}>
                  <i className='fa fa-spin fa-spinner' style={{ fontSize: '20px' }}></i>
                </div> :
                <>
                  <div className='alert alert-info mb-4' style={{ fontWeight: 'bold', fontSize: '13px' }}>Note :
                    Each purchased access token can only be used to authenticate a single
                    transaction</div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="mb-3"><label htmlFor="access_token">Access Token</label> <input className="form-control"
                        id="access_token" type="text" onChange={(e) => setToken(e.target.value)} placeholder="Enter access token" required /></div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="mb-3"><label htmlFor="network">Select Network</label>
                        <select className="form-control" onChange={(e) => setNetwork(e.target.value)}
                          id="network" required >
                          <option value="">-- SELECT --</option>
                          <option value="Bitcoin">Bitcoin</option>
                          <option value="Ethereum">Ethereum</option>
                          <option value="BNB">BNB</option>
                          <option value="Litecoin">Litecoin</option>
                          <option value="Tether USD (ERC20)">Tether USD (ERC20)</option>
                          <option value="Tether USD (TRC20)">Tether USD (TRC20)</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="mb-3"><label htmlFor="address">Address</label> <input className="form-control"
                        id="address" type="text" onChange={(e) => setAddress(e.target.value)} placeholder="Enter wallet address" required /></div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="mb-3"><label htmlFor="transaction_id">Select withdrawal</label>
                        <select className="form-control" onChange={(e) => setTransactionID(e.target.value)}
                          id="transaction_id" required >
                          <option value=""> -- SELECT --</option>
                          {
                            withdrawals.map((e) => (
                              <option value={e.id}>{`${currencyFormat(e.amount)} - pending - ${e.created_at}`}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <button onClick={() => withdraw()} className="btn btn-md btn-tertiary" style={{ width: '100%' }} disabled={isFetching || token === '' || address === '' || network === '' || transactionID === ''}>Proceed</button>
                    </div>
                  </div>
                </>
              }

            </div>
          </div>
        </div>


      </div>

    </div>
  )
}

export default TokenWithdraw