import React, { useEffect, useState } from 'react'
import * as api from '../../services/api_service'
import Transacts from '../../components/Transacts';

function Transactions() {
  
  const [transactions, setTransactions] = useState([])
  const [isFetchingTransactions, setisFetchingTransactions] = useState(true)

  useEffect(() => {
      async function getTransactions() {
        try {
          const resp = await api.deposits();
          console.log('all', resp.data.data)
          setisFetchingTransactions(false)
          setTransactions(resp.data.data)
        } catch (error) {
          setisFetchingTransactions(false)
          console.log(error)
        }
      }

      getTransactions()
      
  }, [])

  return (
    <div className="col-12 col-lg-8">
      <div className="row">
        <div className="col-12 mb-4">
          <Transacts transactions={transactions} isFetchingTransactions={isFetchingTransactions} paginate={6} type='all' />
        </div>
      </div>
    </div>
  )
}

export default Transactions