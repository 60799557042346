import React, { useEffect, useState } from 'react'
import useAuth from '../../hooks/UseAuth';
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../assets/image/logo.png'
import classNames from 'classnames';
import AppHelpers from '../../hooks/AppHook';

function Login() {

    const navigate = useNavigate()
    const { authenticate, isAuthenticated, isAuthenticating, authError, setAuthError, token } = useAuth()
    const { theme } = AppHelpers();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState([])
    const [passwordError, setPasswordError] = useState([])

    useEffect(() => {

        if (token !== null) {
            navigate("/user/dashboard", { replace: true })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, token])

    async function loginUser() {
        setPasswordError([])
        setEmailError([])

        const resp = await authenticate({
            email: email,
            password: password
        })

        if (Object.keys(resp.error).length === 0) {
            return navigate('/user/dashboard', { replace: true })
        } else {
            console.log('Login Errors', resp.error)
            resp.error.errors.password && setPasswordError((err) => [...err, resp.error.errors.password])
            resp.error.errors.email && setEmailError((err) => [...err, resp.error.errors.email])
        }
    }

    return (
        <>
            <section className={classNames("min-vh-100 d-flex align-items-center section-image overlay-soft-dark ", {
                "bg-dark-card text-dark-white": theme === "dark"
            })}
                data-background="">
                <div className="container">
                    <center style={{ marginTop: '-70px' }}>
                        <img src={logo} alt="logo" className='auth-logo mb-4' />
                    </center>
                    <div className="row justify-content-center">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div
                                className={classNames("signin-inner my-4 my-lg-0 bg-white shadow-soft border rounded border-gray-300 p-4 p-lg-5 w-100 fmxw-500", {
                                    "bg-dark-card text-dark-white": theme === "dark"
                                })}>
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h1 className={classNames("mb-0 h3", {
                                        "text-dark-white": theme === "dark"
                                    })}>Sign in to our platform</h1>
                                </div>
                                <form action="#" className="mt-4">
                                    <div className="form-group mb-4"><label htmlFor="email">Your Email</label>
                                        <div className="input-group"><span className="input-group-text" id="basic-addon1"><span
                                            className="fas fa-envelope"></span></span> <input type="email" className="form-control"
                                                placeholder="example@company.com" id="email" onChange={(e) => {
                                                    setEmail(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setEmailError([])
                                                }} required /></div>
                                        {emailError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group mb-4"><label htmlFor="password">Your Password</label>
                                            <div className="input-group"><span className="input-group-text" id="basic-addon2"><span
                                                className="fas fa-unlock-alt"></span></span> <input type="password" placeholder="Password"
                                                    className="form-control" id="password" onChange={(e) => {
                                                        setPassword(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setPasswordError([])
                                                    }} required /></div>
                                            {passwordError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <div className="form-check mb-0"><input className="form-check-input" type="checkbox" value="" id="remember" />
                                                <label className="form-check-label mb-0" htmlFor="remember">Remember me</label></div>
                                            {/* <div><a href="#" className="small text-right">Lost password?</a></div> */}
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        {!isAuthenticating ? <button onClick={loginUser} className="btn btn-primary">Login</button> : <button className="btn btn-primary" disabled><span className='fa fa-spin fa-spinner'></span></button>}
                                        {Object.keys(authError).length > 0 && <p style={{ color: 'red' }}>{authError.message}</p>}
                                    </div>
                                </form>

                                <div className="d-flex justify-content-center align-items-center mt-4"><span className="fw-normal">Not registered?
                                    <Link to="/auth/signup" className={classNames("fw-bold text-underline", {
                                        "text-dark-main": theme === "dark"
                                    })}> Create account</Link></span>
                                </div>
                                <center>
                                    <a href={process.env.REACT_APP_HOME_URL} className={classNames("fw-bold text-underline", {
                                        "text-dark-main": theme === "dark"
                                    })}> Go to Home</a>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div>
                <b>Login</b>

                <p>LOGIN USER</p>

                <div>
                    <input type="email" placeholder='email' onChange={(e) => {
                        setEmail(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setEmailError([])
                    }} /><br />
                    {emailError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}<br />

                    <input type="password" placeholder='password' onChange={(e) => {
                        setPassword(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setPasswordError([])
                    }} /><br />
                    {passwordError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}<br /><br />
                </div>

                {!isAuthenticated ? !isAuthenticating ? <button onClick={loginUser}>Login</button> : <button disabled>Logging in</button> : <button onClick={() => logout()}>Logout</button>}
                {Object.keys(authError).length > 0 && <p style={{ color: 'red' }}>{authError.message}</p>}
            </div> */}
        </>
    )
}

export default Login