import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import * as api from '../services/api_service'

function NewTicket({setTickets}) {

    
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [isFetching, setIsFetching] = useState(false)

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    async function handleAddTicket() {
        setIsFetching(true)
        try {
            const resp = await api.createTicket({title: title, message: message})
            setTickets((t) => [...t, resp.data.data])
            handleClose()
            setIsFetching(false)
            toast.success("Ticket Created successfully! Admin will attend to you shortly", { theme: "dark" })
        } catch (error) {
            setIsFetching(false)
            toast.error(error.response.data.message, { theme: "dark" })
        }
    }

  return (
    <>
        <button onClick={handleShow} className='btn btn-primary ' style={{ marginTop: '-10px' }}>New Tiket <i className='fa fa-plus-circle' /></button>
        <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
                centered
                className='mt-n7'
            >
                <Modal.Body className='pt-4'>
                    <div className='col-12' style={{ 'padding': '5px 10px' }}>
                        <div className='text-left'>
                            <div style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', fontSize: '13px' }} className='bg-primary text-white'>Create New Ticket</div><br />
                            {/* <div style={{ fontSize: '20px', marginLeft: 4, marginBottom: '10px' }}>{activePackage.name && (`${activePackage.name}`).toUpperCase()}</div> */}

                            <div>
                            <div className="mb-2 mt-3">
                                    <label htmlFor="titleInput" style={{ fontSize: '14px' }}>Enter Address</label>
                                    <div className="input-group">
                                        <input className="form-control" placeholder='Ticket Title' id="titleInput" onChange={(e) => setTitle(e.target.value)} style={{ fontSize: '13px' }} />
                                    </div>
                                </div>

                                <div className="mb-2 mt-3">
                                    <label htmlFor="messageInput" style={{ fontSize: '14px' }}>Enter Address</label>
                                    <div className="input-group">
                                        <textarea className="form-control" rows={5} placeholder='Message Body' id="messageInput" onChange={(e) => setMessage(e.target.value)} style={{ fontSize: '13px' }} />
                                    </div>
                                </div>

                                <button className='btn btn-primary mt-2' onClick={handleAddTicket} disabled={title === '' || message === '' || isFetching}>Submit</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
    </>
  )
}

export default NewTicket