import React from 'react'
import logo from '../assets/image/logo.png'
import classNames from 'classnames'
import AppHelpers from '../hooks/AppHook'

function Loader() {
  const { theme } = AppHelpers();
  return (
    <div className={classNames('pageLoader', {
      "bg-dark-card text-dark-white": theme === "dark"
    })}>
      <img src={logo} alt="Themesberg logo" style={{ 'width': '90px' }} /><br />
      <i className='fa fa-circle-notch fa-spin'></i>
    </div>
  )
}

export default Loader