import React from 'react'
import { Link } from 'react-router-dom'

import image from '../assets/img/404.svg'

function NotFoundPage() {
    return (
        <section className="min-vh-100 d-flex align-items-center justify-content-center">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center text-dark d-flex align-items-center justify-content-center">
                        <div><Link to={'/user/dashboard'}><img className="img-fluid w-50"
                            src={image} alt="girl illustration" /></Link>
                            <h1 className="mt-4">404 Page <span className="fw-bolder text-primary">not found</span></h1>
                            <p className="lead my-4 px-lg-11">Oops! Looks like you followed a bad link. If you think this is
                                a problem with us, please tell us.</p><Link className="btn btn-primary animate-hover"
                                    to={'/user/dashboard'}><span
                                        className="fas fa-chevron-left me-3 ps-2 animate-left-3"></span>Go back home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NotFoundPage