import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import * as api from '../services/api_service'
// import UseAuth from '../../hooks/UseAuth';
// import AppHelpers from '../../hooks/AppHook';

function NewWallet({setWallets}) {
    // const { user, setUser } = UseAuth()
    // const { currencyFormat } = AppHelpers()
    const [show, setShow] = useState(false)
    const [account, setAccount] = useState('')
    const [address, setAddress] = useState('')
    const [isFetching, setIsFetching] = useState(false)

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    async function handleAddWallet() {
        setIsFetching(true)
        try {
            const resp = await api.addWallet({account: account, address: address})
            setWallets((w) => [...w, resp.data.data])
            handleClose()
            setIsFetching(false)
            toast.success("Wallet Added Successfully", { theme: "dark" })
        } catch (error) {
            setIsFetching(false)
            toast.error(error.response.data.message, { theme: "dark" })
        }
    }

    return (
        <>
            <button onClick={handleShow} className='btn btn-link btn-sm text-tertiary' ><i className='fa fa-plus' /> New Wallet</button>
            <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
                centered
                className='mt-n7'
            >
                <Modal.Body className='pt-4'>
                    <div className='col-12' style={{ 'padding': '5px 10px' }}>
                        <div className='text-left'>
                            <div style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', fontSize: '13px' }} className='bg-primary text-white'>New Withdrawal Wallet</div><br />
                            {/* <div style={{ fontSize: '20px', marginLeft: 4, marginBottom: '10px' }}>{activePackage.name && (`${activePackage.name}`).toUpperCase()}</div> */}

                            <div>
                                <div className="mb-2 mt-3">
                                    <label htmlFor="walletInput" style={{ fontSize: '12px' }}>Select Currency</label>
                                    <div className="input-group">
                                        <input type="hidden" value='' />
                                        <select className="form-control" id="walletInput" onChange={(e) => setAccount(e.target.value)} style={{ fontSize: '13px' }} >
                                            <option value=''>-- Select Currency --</option>
                                            <option value="Bitcoin">Bitcoin</option>
                                            <option value="Ethereum">Ethereum</option>
                                            <option value="BNB">BNB</option>
                                            <option value="Litecoin">Litecoin</option>
                                            <option value="USDT (ERC20)">USDT (ERC20)</option>
                                            <option value="USDT (TRC20)">USDT (TRC20)</option>

                                        </select>
                                    </div>
                                </div>

                                <div className="mb-2 mt-3">
                                    <label htmlFor="addressInput" style={{ fontSize: '12px' }}>Enter Address</label>
                                    <div className="input-group">
                                        <input type="hidden" value='' />
                                        <input className="form-control" placeholder='Wallet Address' id="addressInput" onChange={(e) => setAddress(e.target.value)} style={{ fontSize: '13px' }} />
                                    </div>
                                </div>

                                <button className='btn btn-primary mt-2' onClick={handleAddWallet} disabled={account === '' || address === '' || isFetching}>Submit</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NewWallet