import React, { useEffect, useRef, useState } from 'react'
import AppHelpers from '../../hooks/AppHook';
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify';
import * as api from '../../services/api_service'
import DownlineEarnings from '../../components/DownlineEarnings';
import UseAuth from '../../hooks/UseAuth';
import classNames from 'classnames';

function Downlines() {

  const { user, isAuthenticated } = UseAuth()
  const { ucfirst, currencyFormat, theme } = AppHelpers()
  const [downlines, setDownlines] = useState([])
  const [earnings, setEarnings] = useState([])
  const [isFetchingDownlines, setIsFetchingDownlines] = useState(true)
  const copyText = useRef()

  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await Promise.all([
          api.downlines(),
          api.downlineEarnings()
        ])
        console.log(resp)
        setDownlines(resp[0].data.data)
        setEarnings(resp[1].data.data)
        setIsFetchingDownlines(false)
      } catch (error) {
        console.log(error)
        setIsFetchingDownlines(false)
      }
    }

    fetchData()
  }, [])

  const toastConfig = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  function handleCopy() {
    console.log(copyText.current.value)
    copyText.current?.select();
    document.execCommand("copy");
    copyText.current.focus()
    toast.success("Copied to clipboard", toastConfig)
  }

  return (
    <div className="col-12 col-lg-8">

      <div className='row'>
        <div className='col-md-6 mb-3'>
          <div className={classNames("card", {
            "bg-dark-card text-dark-white": theme === "dark"
          })}>
            <div className="card-body">
              <div className="d-block h6 fw-normal">
                <div className={classNames('h5 mb-3', {
                                    "text-dark-white": theme === "dark"
                                })}>Downlines</div>

                <div className=" mt-3">
                  <label htmlFor="addressInput" className={classNames('', {
                                    "text-dark-white": theme === "dark"
                                })} style={{ fontSize: '13px' }}>Click To Copy Referal Link To Clipboard</label>
                  <div className="input-group" onClick={handleCopy}>
                    <input type="text" className="form-control" id="addressInput" style={{ fontSize: '12px' }} value={isAuthenticated ? `${process.env.REACT_APP_APP_URL}/auth/signup?ref=${user.id}` : ''} ref={copyText} aria-label="Search" aria-describedby="basic-addon2" readOnly />
                    <span className="input-group-text" id="basic-addon2"><span className="fas fa-clipboard"></span></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className={classNames('card mb-3', {
            "bg-dark-card text-dark-white": theme === "dark"
          } )}>
            <div className="card-body d-block d-md-flex align-items-center">
              <div className={classNames("icon icon-shape icon-md icon-shape-primary rounded-circle me-3 mb-4 mb-md-0", {
                                "text-dark-main": theme === "dark"
                            })}><span className="fas fa-file-invoice-dollar"></span></div>
              <div><span className={classNames("d-block h6 fw-normal", {
                                "text-dark-white": theme === "dark"
                            })}>Referral Balance</span>
                <h5 className={classNames("h3 fw-bold mb-1", {
                                    "text-dark-white": theme === "dark"
                                })}>{isAuthenticated ? currencyFormat(user.referral_balance) : <Skeleton width={150} />}</h5>
                <div className="small mt-2"><small>Referral Bonus from downline</small></div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="row">
        <div className="col-12">
          <div className={classNames("card border-gray-300", {
            "bg-dark-card text-dark-white": theme === "dark"
          })}>
            <div className="card-body d-flex flex-row align-items-center flex-0 border-bottom">
              <div className="d-block">
                <div className={classNames("h5 fw-normal text-gray mt-2", {
                                    "text-dark-white": theme === "dark"
                                })}>My Downlines</div>
                <small>All Refered Users</small>
              </div>

            </div>
            <div className="card-body">
              {
                downlines.length > 0 ?
                  <div style={{ width: '100%', height: 'auto' }}>
                    <div className="table-responsive">
                      <table className='table'>
                        <thead>
                          <tr className={classNames({
                                    "text-dark-white": theme === "dark"
                                })}>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Total <br /> Investments</th>
                            <th>Date Registered</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            downlines.map((e, k) => (
                              <tr key={k} className={classNames({
                                "text-dark-white": theme === "dark"
                            })}>
                                <td>{ucfirst(e.user.firstname)} {ucfirst(e.user.lastname)}</td>
                                <td>{e.user.email}</td>
                                <td>{e.user.investments.length}</td>
                                <td>{e.created_at}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>

                  </div> : isFetchingDownlines ? <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr className={classNames({
                                    "text-dark-white": theme === "dark"
                                })}>
                          <th>Date</th>
                          <th>Name</th>
                          <th>Amount</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.from(Array(4)).map((e, k) => (
                          <tr key={k}>
                            <td><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td><td><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td>
                            <td><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td><td><Skeleton  baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div> :
                    <div>
                      <p>No result found</p>
                    </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12 mb-4">
          <DownlineEarnings earnings={earnings} isFetchingEarnings={isFetchingDownlines} paginate={6} />
        </div>
      </div>

    </div>
  )
}

export default Downlines