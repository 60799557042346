import React from 'react'
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import UseAuth from '../hooks/UseAuth';

function StackingChart({ investments, profit }) {

    const { user, isAuthenticated } = UseAuth()

    let profit_ = profit > 0 ? profit : 0
    let bal_ = user.balance > 0 ? user.balance : 1
    let inv_ = 1

    if (investments.length > 0) {
        let _inv = 5
        investments.forEach((e) => {
            _inv += parseFloat(e.capital)
        })

        inv_ = _inv
    }

    const chartData = {
        labels: [
            'Profit',
            'Investment',
            'Balance'
        ],
        datasets: [{
            label: 'Stacking Activity Chart',
            data: [profit_, inv_, bal_],
            backgroundColor: [
                '#3aaf85',
                '#1c2540',
                '#893168'
            ],
            hoverOffset: 4
        }]        
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
        }
    }

    return (
        <div style={{ width: '70%', height: 'auto', display: 'inline-block' }}>
            {isAuthenticated && <Chart type='pie' options={chartOptions} data={chartData} />}
        </div>
    )
}

export default StackingChart