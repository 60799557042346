import React, { createContext, useEffect, useState } from 'react'

const AppContext = createContext({})

export function AppProvider({ children }) {

    const [theme, setTheme] = useState("light");

    useEffect(() => {
        setTheme(getThemeMode())
        // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [])

    function ucfirst(str) {
        //convert first letter to uppercase
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function currencyFormat(data) {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return formatter.format(data)
    }

    function truncate(str, length = 25) {
        if (str.length < 1) return
        let truncatedString = str
        if (length > 10 && str.length > length ) {
            truncatedString = str.substring(0, length-3)+' ...'
        }
        return truncatedString
    }    

    function getThemeMode() {
        const _theme = localStorage.getItem("theme-mode");
        if (!_theme) {
            localStorage.setItem("theme-mode", "light");
        }
        return localStorage.getItem("theme-mode");
    }

    function toggleThemeMode() {
        const _theme = localStorage.getItem("theme-mode");
        let _newTheme = "";
        if (!_theme) {
            localStorage.setItem("theme-mode", "light");
            _newTheme = "light";
        }else {
            _newTheme = _theme === "light" ? "dark" : "light";
            localStorage.setItem("theme-mode", _newTheme);
        }
        console.log("theme:", _newTheme)
        setTheme(_newTheme);
    }

    return (
        <AppContext.Provider value={
            {
                theme, toggleThemeMode,
                ucfirst,
                currencyFormat,
                truncate,
            }
        }>
            {children}
        </AppContext.Provider>
    )

}

export default AppContext