import React, { useEffect, useState } from 'react'
import useAuth from '../../hooks/UseAuth';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import logo from '../../assets/image/logo.png'
import AppHelpers from '../../hooks/AppHook';
import classNames from 'classnames';

function Register() {

    const navigate = useNavigate()
    const { signup, isAuthenticated, isAuthenticating, authError, setAuthError, token } = useAuth()
    const { theme } = AppHelpers();

    const [searchParams] = useSearchParams();
    const queryParam_ = searchParams.get("ref") || null;
    const queryParam = queryParam_?.toLowerCase();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [gender, setGender] = useState('')
    // const [confirmPassword, setConfirmPassword] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [phone, setPhone] = useState('')
    const [country, setCountry] = useState('')

    const [emailError, setEmailError] = useState([])
    const [passwordError, setPasswordError] = useState([])
    const [genderError, setGenderError] = useState([])
    // const [confirmPasswordError, setConfirmPasswordError] = useState([])
    const [firstnameError, setFirstnameError] = useState([])
    const [lastnameError, setLastnameError] = useState([])
    const [phoneError, setPhoneError] = useState([])
    const [countryError, setCountryError] = useState([])

    useEffect(() => {

        if (token !== null) {
            navigate("/user/dashboard", { replace: true })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, token])

    async function signupUser() {
        setPasswordError([])
        setEmailError([])
        setFirstnameError([])
        setLastnameError([])
        setPhoneError([])
        setCountryError([])
        setGenderError([])
        const resp = await signup({
            email: email,
            password: password,
            gender: gender,
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            country: country,
            ref: queryParam,
        })

        if (Object.keys(resp.error).length === 0) {
            return navigate('/user/dashboard', { replace: true })
        } else {
            console.log('Register Errors', resp.error)
            // resp.error.errors.password && console.log('password', resp.error.errors.password)
            resp.error.errors.password && setPasswordError((err) => [...err, resp.error.errors.password])
            resp.error.errors.email && setEmailError((err) => [...err, resp.error.errors.email])
            resp.error.errors.firstname && setFirstnameError((err) => [...err, resp.error.errors.firstname])
            resp.error.errors.lastname && setLastnameError((err) => [...err, resp.error.errors.lastname])
            resp.error.errors.phone && setPhoneError((err) => [...err, resp.error.errors.phone])
            resp.error.errors.country && setCountryError((err) => [...err, resp.error.errors.country])
            resp.error.errors.gender && setGenderError((err) => [...err, resp.error.errors.gender])
        }
    }

    return (
        <div>

            <section className={classNames("min-vh-100 d-flex align-items-center section-image overlay-soft-dark ", {
                "bg-dark-card text-dark-white": theme === "dark"
            })} data-background="">
                <div className="container">
                    <center style={{ marginTop: '20px' }}>
                        <img src={logo} alt="logo" className='auth-logo mb-4' />
                    </center>
                    <div className="row justify-content-center">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div
                                className={classNames("signin-inner my-4 my-lg-0 bg-white shadow-soft border rounded border-gray-300 p-4 p-lg-5 w-100 fmxw-500", {
                                    "bg-dark-card text-dark-white": theme === "dark"
                                })}>
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h1 className={classNames("mb-0 h3", {
                                        "text-dark-white": theme === "dark"
                                    })}>Sign up to our platform</h1>
                                </div>
                                <form action="#" className="mt-4">
                                    <div className="form-group mb-4"><label htmlFor="firstname">First name</label>
                                        <div className="input-group"><span className="input-group-text" id="basic-addon1"><span
                                            className="fas fa-user"></span></span> <input type="text" className="form-control"
                                                placeholder="First name" id="firstname" onChange={(e) => {
                                                    setFirstname(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setFirstnameError([])
                                                }} required /></div>
                                        {firstnameError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}
                                    </div>

                                    <div className="form-group mb-4"><label htmlFor="lastname">Last name</label>
                                        <div className="input-group"><span className="input-group-text" id="basic-addon1"><span
                                            className="fas fa-user"></span></span> <input type="text" className="form-control"
                                                placeholder="Last name" id="lastname" onChange={(e) => {
                                                    setLastname(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setLastnameError([])
                                                }} required /></div>
                                        {lastnameError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}
                                    </div>

                                    <div className="form-group mb-4"><label htmlFor="email">Your Email</label>
                                        <div className="input-group"><span className="input-group-text" id="basic-addon1"><span
                                            className="fas fa-envelope"></span></span> <input type="email" className="form-control"
                                                placeholder="example@company.com" id="email" onChange={(e) => {
                                                    setEmail(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setEmailError([])
                                                }} required /></div>
                                        {emailError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}
                                    </div>

                                    <div className="form-group mb-4"><label htmlFor="phone">Phone</label>
                                        <div className="input-group"><span className="input-group-text" id="basic-addon1"><span
                                            className="fas fa-phone"></span></span> <input type="text" className="form-control"
                                                placeholder="Phone" id="phone" onChange={(e) => {
                                                    setPhone(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setPhoneError([])
                                                }} required /></div>
                                        {phoneError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}
                                    </div>


                                    <div className="form-group mb-4"><label htmlFor="country">Country</label>
                                        <div className="input-group"><span className="input-group-text" id="basic-addon1"><span
                                            className="fas fa-globe"></span></span>

                                            <select className="form-control" id="country" onChange={(e) => {
                                                setCountry(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setCountryError([])
                                            }} required>
                                                <option value="">-- SELECT --</option>
                                                <option value="Afganistan">Afghanistan</option>
                                                <option value="Albania">Albania</option>
                                                <option value="Algeria">Algeria</option>
                                                <option value="American Samoa">American Samoa</option>
                                                <option value="Andorra">Andorra</option>
                                                <option value="Angola">Angola</option>
                                                <option value="Anguilla">Anguilla</option>
                                                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                                <option value="Argentina">Argentina</option>
                                                <option value="Armenia">Armenia</option>
                                                <option value="Aruba">Aruba</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Azerbaijan">Azerbaijan</option>
                                                <option value="Bahamas">Bahamas</option>
                                                <option value="Bahrain">Bahrain</option>
                                                <option value="Bangladesh">Bangladesh</option>
                                                <option value="Barbados">Barbados</option>
                                                <option value="Belarus">Belarus</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Belize">Belize</option>
                                                <option value="Benin">Benin</option>
                                                <option value="Bermuda">Bermuda</option>
                                                <option value="Bhutan">Bhutan</option>
                                                <option value="Bolivia">Bolivia</option>
                                                <option value="Bonaire">Bonaire</option>
                                                <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                                <option value="Botswana">Botswana</option>
                                                <option value="Brazil">Brazil</option>
                                                <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                                <option value="Brunei">Brunei</option>
                                                <option value="Bulgaria">Bulgaria</option>
                                                <option value="Burkina Faso">Burkina Faso</option>
                                                <option value="Burundi">Burundi</option>
                                                <option value="Cambodia">Cambodia</option>
                                                <option value="Cameroon">Cameroon</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Canary Islands">Canary Islands</option>
                                                <option value="Cape Verde">Cape Verde</option>
                                                <option value="Cayman Islands">Cayman Islands</option>
                                                <option value="Central African Republic">Central African Republic</option>
                                                <option value="Chad">Chad</option>
                                                <option value="Channel Islands">Channel Islands</option>
                                                <option value="Chile">Chile</option>
                                                <option value="China">China</option>
                                                <option value="Christmas Island">Christmas Island</option>
                                                <option value="Cocos Island">Cocos Island</option>
                                                <option value="Colombia">Colombia</option>
                                                <option value="Comoros">Comoros</option>
                                                <option value="Congo">Congo</option>
                                                <option value="Cook Islands">Cook Islands</option>
                                                <option value="Costa Rica">Costa Rica</option>
                                                <option value="Cote DIvoire">Cote DIvoire</option>
                                                <option value="Croatia">Croatia</option>
                                                <option value="Cuba">Cuba</option>
                                                <option value="Curaco">Curacao</option>
                                                <option value="Cyprus">Cyprus</option>
                                                <option value="Czech Republic">Czech Republic</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Djibouti">Djibouti</option>
                                                <option value="Dominica">Dominica</option>
                                                <option value="Dominican Republic">Dominican Republic</option>
                                                <option value="East Timor">East Timor</option>
                                                <option value="Ecuador">Ecuador</option>
                                                <option value="Egypt">Egypt</option>
                                                <option value="El Salvador">El Salvador</option>
                                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                <option value="Eritrea">Eritrea</option>
                                                <option value="Estonia">Estonia</option>
                                                <option value="Ethiopia">Ethiopia</option>
                                                <option value="Falkland Islands">Falkland Islands</option>
                                                <option value="Faroe Islands">Faroe Islands</option>
                                                <option value="Fiji">Fiji</option>
                                                <option value="Finland">Finland</option>
                                                <option value="France">France</option>
                                                <option value="French Guiana">French Guiana</option>
                                                <option value="French Polynesia">French Polynesia</option>
                                                <option value="French Southern Ter">French Southern Ter</option>
                                                <option value="Gabon">Gabon</option>
                                                <option value="Gambia">Gambia</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Germany">Germany</option>
                                                <option value="Ghana">Ghana</option>
                                                <option value="Gibraltar">Gibraltar</option>
                                                <option value="Great Britain">Great Britain</option>
                                                <option value="Greece">Greece</option>
                                                <option value="Greenland">Greenland</option>
                                                <option value="Grenada">Grenada</option>
                                                <option value="Guadeloupe">Guadeloupe</option>
                                                <option value="Guam">Guam</option>
                                                <option value="Guatemala">Guatemala</option>
                                                <option value="Guinea">Guinea</option>
                                                <option value="Guyana">Guyana</option>
                                                <option value="Haiti">Haiti</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Honduras">Honduras</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Iceland">Iceland</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="India">India</option>
                                                <option value="Iran">Iran</option>
                                                <option value="Iraq">Iraq</option>
                                                <option value="Ireland">Ireland</option>
                                                <option value="Isle of Man">Isle of Man</option>
                                                <option value="Israel">Israel</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Jamaica">Jamaica</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Jordan">Jordan</option>
                                                <option value="Kazakhstan">Kazakhstan</option>
                                                <option value="Kenya">Kenya</option>
                                                <option value="Kiribati">Kiribati</option>
                                                <option value="Korea North">Korea North</option>
                                                <option value="Korea Sout">Korea South</option>
                                                <option value="Kuwait">Kuwait</option>
                                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                <option value="Laos">Laos</option>
                                                <option value="Latvia">Latvia</option>
                                                <option value="Lebanon">Lebanon</option>
                                                <option value="Lesotho">Lesotho</option>
                                                <option value="Liberia">Liberia</option>
                                                <option value="Libya">Libya</option>
                                                <option value="Liechtenstein">Liechtenstein</option>
                                                <option value="Lithuania">Lithuania</option>
                                                <option value="Luxembourg">Luxembourg</option>
                                                <option value="Macau">Macau</option>
                                                <option value="Macedonia">Macedonia</option>
                                                <option value="Madagascar">Madagascar</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Malawi">Malawi</option>
                                                <option value="Maldives">Maldives</option>
                                                <option value="Mali">Mali</option>
                                                <option value="Malta">Malta</option>
                                                <option value="Marshall Islands">Marshall Islands</option>
                                                <option value="Martinique">Martinique</option>
                                                <option value="Mauritania">Mauritania</option>
                                                <option value="Mauritius">Mauritius</option>
                                                <option value="Mayotte">Mayotte</option>
                                                <option value="Mexico">Mexico</option>
                                                <option value="Midway Islands">Midway Islands</option>
                                                <option value="Moldova">Moldova</option>
                                                <option value="Monaco">Monaco</option>
                                                <option value="Mongolia">Mongolia</option>
                                                <option value="Montserrat">Montserrat</option>
                                                <option value="Morocco">Morocco</option>
                                                <option value="Mozambique">Mozambique</option>
                                                <option value="Myanmar">Myanmar</option>
                                                <option value="Nambia">Nambia</option>
                                                <option value="Nauru">Nauru</option>
                                                <option value="Nepal">Nepal</option>
                                                <option value="Netherland Antilles">Netherland Antilles</option>
                                                <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                                <option value="Nevis">Nevis</option>
                                                <option value="New Caledonia">New Caledonia</option>
                                                <option value="New Zealand">New Zealand</option>
                                                <option value="Nicaragua">Nicaragua</option>
                                                <option value="Niger">Niger</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Niue">Niue</option>
                                                <option value="Norfolk Island">Norfolk Island</option>
                                                <option value="Norway">Norway</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Pakistan">Pakistan</option>
                                                <option value="Palau Island">Palau Island</option>
                                                <option value="Palestine">Palestine</option>
                                                <option value="Panama">Panama</option>
                                                <option value="Papua New Guinea">Papua New Guinea</option>
                                                <option value="Paraguay">Paraguay</option>
                                                <option value="Peru">Peru</option>
                                                <option value="Phillipines">Philippines</option>
                                                <option value="Pitcairn Island">Pitcairn Island</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Portugal">Portugal</option>
                                                <option value="Puerto Rico">Puerto Rico</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="Republic of Montenegro">Republic of Montenegro</option>
                                                <option value="Republic of Serbia">Republic of Serbia</option>
                                                <option value="Reunion">Reunion</option>
                                                <option value="Romania">Romania</option>
                                                <option value="Russia">Russia</option>
                                                <option value="Rwanda">Rwanda</option>
                                                <option value="St Barthelemy">St Barthelemy</option>
                                                <option value="St Eustatius">St Eustatius</option>
                                                <option value="St Helena">St Helena</option>
                                                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                                <option value="St Lucia">St Lucia</option>
                                                <option value="St Maarten">St Maarten</option>
                                                <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                                <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                                <option value="Saipan">Saipan</option>
                                                <option value="Samoa">Samoa</option>
                                                <option value="Samoa American">Samoa American</option>
                                                <option value="San Marino">San Marino</option>
                                                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                                <option value="Saudi Arabia">Saudi Arabia</option>
                                                <option value="Senegal">Senegal</option>
                                                <option value="Seychelles">Seychelles</option>
                                                <option value="Sierra Leone">Sierra Leone</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Slovakia">Slovakia</option>
                                                <option value="Slovenia">Slovenia</option>
                                                <option value="Solomon Islands">Solomon Islands</option>
                                                <option value="Somalia">Somalia</option>
                                                <option value="South Africa">South Africa</option>
                                                <option value="Spain">Spain</option>
                                                <option value="Sri Lanka">Sri Lanka</option>
                                                <option value="Sudan">Sudan</option>
                                                <option value="Suriname">Suriname</option>
                                                <option value="Swaziland">Swaziland</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Syria">Syria</option>
                                                <option value="Tahiti">Tahiti</option>
                                                <option value="Taiwan">Taiwan</option>
                                                <option value="Tajikistan">Tajikistan</option>
                                                <option value="Tanzania">Tanzania</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Togo">Togo</option>
                                                <option value="Tokelau">Tokelau</option>
                                                <option value="Tonga">Tonga</option>
                                                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                                <option value="Tunisia">Tunisia</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="Turkmenistan">Turkmenistan</option>
                                                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                                <option value="Tuvalu">Tuvalu</option>
                                                <option value="Uganda">Uganda</option>
                                                <option value="United Kingdom">United Kingdom</option>
                                                <option value="Ukraine">Ukraine</option>
                                                <option value="United Arab Erimates">United Arab Emirates</option>
                                                <option value="United States of America">United States of America</option>
                                                <option value="Uraguay">Uruguay</option>
                                                <option value="Uzbekistan">Uzbekistan</option>
                                                <option value="Vanuatu">Vanuatu</option>
                                                <option value="Vatican City State">Vatican City State</option>
                                                <option value="Venezuela">Venezuela</option>
                                                <option value="Vietnam">Vietnam</option>
                                                <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                                <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                                <option value="Wake Island">Wake Island</option>
                                                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                                <option value="Yemen">Yemen</option>
                                                <option value="Zaire">Zaire</option>
                                                <option value="Zambia">Zambia</option>
                                                <option value="Zimbabwe">Zimbabwe</option>
                                            </select>

                                        </div>
                                        {countryError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}
                                    </div>

                                    <div className="form-group mb-4"><label htmlFor="gender">Gender</label>
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <span className="fas fa-male"></span>
                                                <span className="fas fa-female"></span>
                                            </span>

                                            <select className="form-control" id="gender" onChange={(e) => {
                                                setGender(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setGenderError([])
                                            }} required>
                                                <option value="">-- SELECT --</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="none">Do not specify</option>
                                            </select>

                                        </div>
                                        {genderError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}
                                    </div>

                                    <div className="form-group">
                                        <div className="form-group mb-4"><label htmlFor="password">Enter Password</label>
                                            <div className="input-group"><span className="input-group-text" id="basic-addon2"><span
                                                className="fas fa-unlock-alt"></span></span> <input type="password" placeholder="Password"
                                                    className="form-control" id="password" onChange={(e) => {
                                                        setPassword(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setPasswordError([])
                                                    }} required /></div>
                                            {passwordError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <div className="form-check mb-0"><input className="form-check-input" type="checkbox" value="" id="remember" />
                                                <label className="form-check-label mb-0" htmlFor="remember">Remember me</label></div>
                                            {/* <div><a href="#" className="small text-right">Lost password?</a></div> */}
                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                        <div className="form-group mb-4"><label htmlFor="password_confirmation">Confirm Password</label>
                                            <div className="input-group"><span className="input-group-text" id="basic-addon2"><span
                                                className="fas fa-unlock-alt"></span></span> <input type="password" placeholder="Confirm Password"
                                                    className="form-control" id="password_confirmation" onChange={(e) => {
                                                        setConfirmPassword(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setConfirmPasswordError([])
                                                    }} required /></div>
                                            {confirmPasswordError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}
                                        </div>
                                    </div> */}
                                    <div className="d-grid">
                                        {!isAuthenticating ? <button onClick={signupUser} className="btn btn-primary">Sign Up</button> : <button className="btn btn-primary" disabled><span className='fa fa-spin fa-spinner'></span></button>}
                                        {Object.keys(authError).length > 0 && <p style={{ color: 'red' }}>{authError.message}</p>}
                                    </div>
                                </form>

                                <div className="d-flex justify-content-center align-items-center mt-4"><span className="fw-normal">Already registered ?
                                    <Link to="/auth/login" className={classNames("fw-bold text-underline", {
                                        "text-dark-main": theme === "dark"
                                    })}> Login</Link></span></div>
                                <center>
                                    <a href={process.env.REACT_APP_HOME_URL} className={classNames("fw-bold text-underline", {
                                        "text-dark-main": theme === "dark"
                                    })}> Go to Home</a>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <div>

                <input type="text" placeholder='firstname' onChange={(e) => {
                    setFirstname(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setFirstnameError([])
                }} /><br />
                {firstnameError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}<br />

                <input type="text" placeholder='lastname' onChange={(e) => {
                    setLastname(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setLastnameError([])
                }} /><br />
                {lastnameError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}<br />

                <input type="email" placeholder='email' onChange={(e) => {
                    setEmail(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setEmailError([])
                }} /><br />
                {emailError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}<br />

                <input type="text" placeholder='country' onChange={(e) => {
                    setCountry(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setCountryError([])
                }} /><br />
                {countryError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}<br />

                <input type="password" placeholder='password' onChange={(e) => {
                    setPassword(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setPasswordError([])
                }} /><br />
                {passwordError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}<br /><br />
            </div>

            {!isAuthenticating ? <button onClick={signupUser}>Register</button> : <button disabled>Please wait</button>}
            {Object.keys(authError).length > 0 && <p style={{ color: 'red' }}>{authError.message}</p>} */}
        </div>
    )
}

export default Register