import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'

import * as api from '../../services/api_service'
import UseAuth from '../../hooks/UseAuth';
import { useNavigate } from 'react-router-dom';
import AppHelpers from '../../hooks/AppHook';
import classNames from 'classnames';

function Packages() {

  const { user, setUser } = UseAuth()
  const { currencyFormat, theme } = AppHelpers()
  const navigate = useNavigate()
  const [plans, setPlans] = useState([])
  const [isFetchingPlans, setIsFetchingPlans] = useState(true)
  const [show, setShow] = useState(false)
  const [activePackage, setActivePackage] = useState({})
  const [amount, setAmount] = useState(0)
  const [isPostingInvest, setIsPostingInvest] = useState(false)

  const handleClose = () => {
    setShow(false)
    setActivePackage({})
  }

  const handleShow = (e) => {
    if (e.min > user.balance) return toast.error('Insufficient balance', toastThemeConfig.dark)
    setActivePackage(e)
    setShow(true)
  }

  const toastConfig = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light"
  }
  const toastThemeConfig = {
    fill: {
      ...toastConfig,
      theme: "colored"
    },
    light: {
      ...toastConfig,
      theme: "light"
    },
    dark: {
      ...toastConfig,
      theme: "dark"
    },
  }

  useEffect(() => {
    getPlans()
  }, [])

  async function getPlans() {
    try {
      const resp = await api.getPlans()
      setPlans(resp.data)
      setIsFetchingPlans(false)
      // console.log(resp.data)
    } catch (error) {
      setIsFetchingPlans(false)
      console.log(error)
    }
  }

  async function handleInvest() {
    setIsPostingInvest(true)
    try {
      const resp = await api.invest({ capital: amount, p_id: activePackage.id })
      setUser((user_) => {
        return { ...user_, balance: user_.balance - parseFloat(resp.data.data.capital) }
      })
      navigate('/user/investments', { state: { toast: true, toastConfig: { ...toastThemeConfig.dark, autoClose: 8000, position: "top-center" } } })

    } catch (error) {
      toast.error(error.response.data.message, { ...toastThemeConfig.dark, position: 'top-center' })
      setIsPostingInvest(false)
    }
  }

  return (
    <div className="col-12 col-lg-8">
      <div className="col-12 mb-4">
        <div className={classNames('h5 text-left', {
          "text-dark-white": theme === "dark"
        })}>Investment Packages</div>
      </div>

      <div className="row">

        {plans.length > 0 ? plans.map((e, k) => (
          <div key={k} className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
            <div className={classNames("card shadow border-gray-300 text-center py-4", {
              "bg-dark-800": theme === "dark"
            })}>
              <div className="card-header border-0 p-3">
                <h2 className={classNames("h3 text-primary mb-4", {
                  "text-dark-main": theme === "dark"
                })}>{e.name}</h2><span className="d-block"><span
                  className="display-4 text-tertiary fw-bold "><span
                    className="align-top font-medium">$</span>{e.min} </span><span
                      className={classNames("d-block text-gray font-small", {
                        "text-dark-white": theme === "dark"
                      })}>/ min</span></span>
              </div>
              <div className="card-body">
                <ul className="list-unstyled mb-4">
                  <li className={classNames("list-item pb-3", {
                    "text-dark-white": theme === "dark"
                  })}>Max: <strong>${e.max}</strong></li>
                  <li className={classNames("list-item pb-3", {
                    "text-dark-white": theme === "dark"
                  })}>Interest Rate: <strong>{e.interest}%</strong></li>
                  <li className={classNames("list-item pb-3", {
                    "text-dark-white": theme === "dark"
                  })}>Duration: <strong>{e.interval} Days</strong></li>
                </ul>
                <div className="d-grid"><button onClick={() => { handleShow(e) }} className="btn btn-tertiary">Select Package</button></div>
              </div>
            </div><br />
          </div>
        )) :
          isFetchingPlans ?
            Array.from(Array(6)).map((e, k) => (
              <div key={k} className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
                <div className={classNames("card shadow border-gray-300 text-center py-4", {
                  "bg-dark-800": theme === "dark"
                })}>
                  <div className="card-header border-0 p-3">
                    <h2 className="h3 text-primary mb-4"><Skeleton width={100} baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></h2><span className="d-block"><span
                      className="display-4 text-tertiary fw-bold "><span
                        className="align-top font-medium"><Skeleton width={100} baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></span></span><span
                          className={classNames("d-block text-gray font-small", {
                            "text-dark-white": theme === "dark"
                          })}>/ min</span></span>
                  </div>
                  <div className="card-body">
                    <ul className="list-unstyled mb-4">
                      <li className={classNames("list-item pb-3", {
                        "text-dark-white": theme === "dark"
                      })}>Max: <strong><Skeleton width={60} baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></strong></li>
                      <li className={classNames("list-item pb-3", {
                        "text-dark-white": theme === "dark"
                      })}>Interest Rate: <Skeleton width={60} baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></li>
                      <li className={classNames("list-item pb-3", {
                        "text-dark-white": theme === "dark"
                      })}>Duration: <Skeleton width={60} baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></li>
                    </ul>
                    <div className="d-grid"><Skeleton width={150} baseColor={theme === "dark" && '#3f3f46'} highlightColor={theme === "dark" && '#52525b'} /></div>
                  </div>
                </div><br />
              </div>
            )) :
            <span>No Package At The Moment</span>
        }


      </div>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
        className='mt-n7'
      >
        <Modal.Body className='pt-4'>
          <div className='col-12' style={{ 'padding': '5px 10px' }}>
            <div className='text-left'>
              <div style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', fontSize: '13px' }} className='bg-primary text-white'>Selected Package</div><br />
              <div style={{ fontSize: '20px', marginLeft: 4, marginBottom: '10px' }}>{activePackage.name && (`${activePackage.name}`).toUpperCase()}</div>

              <div>
                <div className='mb-2'>
                  <span>Enter Amount To Invest</span>
                </div>

                <div style={{ display: 'flex', fontSize: '13px' }} className='mb-3'>
                  <div style={{ display: 'inline-block' }}><b>Note :</b></div> &nbsp;
                  <div style={{ display: 'inline-block' }}>
                    <span>Minimum Capital : {activePackage.min && currencyFormat(activePackage.min)}</span><br />
                    <span>Maximum Capital : {activePackage.max && currencyFormat(activePackage.max)}</span><br />
                  </div>
                </div>

                <div style={{ display: 'flex', fontSize: '13px' }}>
                  <div style={{ display: 'inline-block', marginRight: '10px' }}><b>Amount ($):</b></div>
                  <div style={{ display: 'inline-block' }}>
                    <input type="number" className='form-control' placeholder='0.00' onChange={(e) => setAmount(e.target.value)} readOnly={isPostingInvest} />
                    <div className='text-danger' style={{ display: amount > 0 && amount < activePackage.min ? 'inline-block' : 'none' }}>Amount is lesser than minimum investment capital</div>
                    <div className='text-danger' style={{ display: amount > 0 && amount > activePackage.max ? 'inline-block' : 'none' }}>Amount is greater than minimum investment capital</div>
                    <button onClick={handleInvest} className='btn btn-primary mt-4' disabled={amount <= 0 || amount < activePackage.min || amount > activePackage.max || isPostingInvest}><span style={{ padding: '0px 10px' }}>Submit</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default Packages